import { gql } from 'graphql-tag';

export const CommonEventFieldsFragment = gql`
  fragment commonEventFields on Event {
    id
    track_id
    account_id
    image
    image_id
    logo
    logo_id
    name
    day
    date
    month
    year
    listDates
    start_date
    end_date
    sale_start
    sale_end
    eventDates
    status
    status_color
    series_ids
    color_code
    fullDate
    added_by
    admin_website_url
    admin_schedule_url
    capacity
    individual_tickets
    nextStartTime
    isMultiDay
    nextFullDate
    international_fee
    hide_fee
    marketing_fee
    user {
      id
      first_name
      last_name
      middle_name
    }
    track {
      id
      name
      infos {
        id
        logo
        logo_id
        link
        text
        info {
          id
          name
        }
        order
        video_url
        image_url
        image_id
        pdf_url
        pdf_id
      }
      timezone
    }
    series {
      id
      name
      infos {
        id
        logo
        logo_id
        link
        text
        info {
          id
          name
        }
        order
        video_url
        image_url
        image_id
        pdf_url
        pdf_id
      }
    }
    faqs {
      id
      question
      answer
      order
    }
    notes {
      id
      note
    }
    payout_status
    pos
    pos_settings {
      payment
      calculator
      ticket_delivery
      receipt_delivery
    }
  }
`;

export const TicketFieldsFragment = gql`
  fragment ticketFields on EventTicket {
    id
    name
    price
    date
    start_date
    end_date
    type
    color_code
    isMultiDay
    gate_time
    start_time
    form_id
    form {
      id
    }
    description
    order
    fee_dollar
    pos_fee_dollar
    fee_percent
    pos_fee_percent
    is_featured
    featured_text
    track_price
    limit
    reserved_seat
    category_id
    sale_start
    sale_end
    on_sale_time
    off_sale_time
    reserved_seat_category
    category_id
    ticket_tiers {
      id
      price
      label
      ticketType
      track_price
    }
    visibility
    code
    code_date
    code_time
    reserved_seat_prefix
    reserved_seat_section
  }
`;

export const LapSponsorshipFragment = gql`
  fragment lapSponsorshipFields on LapSponsorship {
    id
    name
    price
    num_laps
    payout_1
    payout_2
    payout_3
  }
`;

export const EditTicketFieldsFragment = gql`
  fragment editTicketFields on Event {
    ...commonEventFields
    off_sale_time
    on_sale_time
    user_id
    registration_sale_start
    registration_sale_end
    registration_off_time
    about
    schedule_details
    twitter
    facebook
    instagram
    admin_times {
      id
      event_date
      start_time
      gate_time
    }
    waivers {
      id
      name
    }
    tickets {
      ...ticketFields
    }
    admin_tickets {
      ...ticketFields
    }
    admin_multiday_tickets {
      ...ticketFields
    }
    admin_other_tickets {
      ...ticketFields
    }
    admin_bundle_tickets {
      ...ticketFields
      associated_tickets {
        id
        name
        quantity
      }
    }
    registration_email
    other_fees {
      id
      fee_price
      fee_amount
      minimum
      maximum
    }
    registration_fees {
      id
      fee_price
      fee_amount
      minimum
      maximum
    }
    matrix {
      id
      name
    }
    lap_sponsorships {
      ...lapSponsorshipFields
    }
    promoter_name
    promoter_email
    promoter_phone
    promoter_organization
    promoter_website
    infos {
      id
      logo
      logo_id
      link
      text
      info {
        id
        name
      }
      order
      video_url
      image_url
      image_id
      pdf_url
      pdf_id
      type
      track_id
      series_id
    }
    admin_infos {
      id
      logo
      logo_id
      link
      text
      info {
        id
        name
      }
      order
      video_url
      image_url
      image_id
      pdf_url
      pdf_id
      type
      track_id
      series_id
    }
    url
    info_video_url
    promo_image_id
    twitter_image_id
    facebook_image_id
    facebook_image
    twitter_image
    promo_image
    show_pitpay
    show_tickethoss
    schedule_detail_image_id
    schedule_detail_image
    schedule_detail_url
    seats_id
    code
    code_date
    code_time
  }
  ${CommonEventFieldsFragment}
  ${TicketFieldsFragment}
  ${LapSponsorshipFragment}
`;

export const UpcomingEventFieldsFragment = gql`
  fragment upcomingEventFields on Event {
    id
    logo
    fullDate
    nextGateTime
    nextFullDate
    start_date
    next_date
    name
    end_date
    isMultiDay
    listDates
    month
    day
    date
    track {
      id
      logo
      name
      distance
      lat
      lng
    }
    series {
      id
      name
    }
  }
`;
