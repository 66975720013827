import moment from 'moment';
import React, { useState } from 'react';
import { useLocation, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
  EventYearFilter,
  withEventYearFilterContext,
} from 'components/Events/Components/EventYearFilter';
import { useGetUserEvents } from 'components/Events/gql';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import { SearchableListContainer } from 'components/SearchableListContainer';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import ExportCsvTrackEventsReport from '../ExportCsvTrackEventsReport';
import { PrintTrackEventsReport } from '../PrintTrackEventsReport';

const Page = withEventYearFilterContext(({ theme, history }) => {
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const { data } = useGetUserEvents();

  const events = data?.getEventsByAccount?.results ?? [];

  const showTracks = events.reduce((acc, event) => {
    if (true === acc) {
      return acc;
    }

    if (false === acc) {
      return event.track.id;
    } else {
      return acc !== event.track.id ? true : event.track.id;
    }
  }, false);

  return (
    <>
      {data?.getEventsByAccount?.count > 0 ? (
        <div
          style={{
            marginTop: 35,
            marginLeft: '80%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ExportCsvTrackEventsReport headerText={''} />
          <PrintTrackEventsReport headerText={''} />
        </div>
      ) : null}
      <SearchableListContainer
        pageCount={data?.getEventsByAccount?.count}
        paginated
        searchInputPlaceholder="Search Events"
        title="Events"
        titleBarContent={<EventYearFilter />}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col">Date</TableCell>
              <TableCell scope="col">Event</TableCell>
              {true === showTracks ? (
                <TableCell scope="col">Track</TableCell>
              ) : null}
              <TableCell scope="col">
                <LineHeightText>On Sale Date</LineHeightText>
              </TableCell>
              <TableCell scope="col">Status</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {events.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <LineHeightText>
                    {item.isMultiDay
                      ? `${item.month.toUpperCase()} ${item.listDates} - ${
                          item.year
                        }`
                      : `${item.day} ${item.date} - ${item.year}`}
                  </LineHeightText>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ color: theme.colors.primary }}
                    to={{
                      pathname: `/admin-${userType}/events/menu/${item.id}`,
                      state: { event: item },
                    }}
                  >
                    <LineHeightText>{item.name}</LineHeightText>
                  </Link>
                </TableCell>
                {true === showTracks ? (
                  <TableCell>
                    <LineHeightText>{item.track.name}</LineHeightText>
                  </TableCell>
                ) : null}
                <TableCell>
                  <LineHeightText>
                    {moment(item.sale_start)
                      .format('ddd MMM D - YYYY')
                      .toUpperCase() ??
                      moment(item.registration_sale_start)
                        .format('ddd MMM D - YYYY')
                        .toUpperCase() ??
                      ''}
                  </LineHeightText>
                </TableCell>
                <TableCell>
                  <LineHeightText>{item.status.toUpperCase()}</LineHeightText>{' '}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SearchableListContainer>

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/${userType}/settings/1`}
        history={history}
      />
    </>
  );
});

export default withRouter(withTheme(Page));
