import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import ContainerHeader from 'components/ContainerHeader';
import {
  TableComponent as Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'components/Table';
import Text from 'components/Text';
import { ResponsiveCol } from 'pages/app/Events/AddEvents/styles';
import { TitleContainer } from 'pages/track/Contact/style';

const MainContainer = styled.div`
  padding: 10px 30px;
`;

const MemberDetail = ({ transaction }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <MainContainer>
      <ContainerHeader borderWidth={'0px'} style={{ paddingLeft: 0 }}>
        <TitleContainer>
          <Text
            type="heading"
            color="#3C4144"
            inlineStyle={{ marginRight: 25 }}
          >
            Financial Breakdown
          </Text>
        </TitleContainer>
      </ContainerHeader>
      {!isMobile ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" padding={'10px'}>
                Subtotal
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Discount
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Credit Applied
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Refund Issued
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Credit Issued
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Fee
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Total
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell padding={'10px'}>{transaction.subtotal}</TableCell>
              <TableCell
                padding={'10px'}
              >{`-${transaction.discount}`}</TableCell>
              <TableCell padding={'10px'}>{`-${transaction.credit}`}</TableCell>
              <TableCell padding={'10px'}>
                {`-${transaction.refunded_amount}`}
              </TableCell>
              <TableCell padding={'10px'}>
                {transaction.credits_issued}
              </TableCell>
              <TableCell padding={'10px'}>{transaction.fee}</TableCell>
              <TableCell padding={'10px'}>{transaction.total}</TableCell>
              {/* <TableCell padding={'10px'}>
                {moment(purchase.purchase_date).format('MMM DD - YYYY h:mm A')}
              </TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Subtotal:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.subtotal}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Discount:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.discount}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Credit:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.credit}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Fee :
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.fee}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Total :
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.total}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Refund Amount :
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.refunded_amount}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Credit Issued :
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.credits_issued}
            </Text>
          </ResponsiveCol>
        </>
      )}
    </MainContainer>
  );
};

export default MemberDetail;
