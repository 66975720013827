import { NavLink } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) =>
      props.theme.colors.background.light}!important;
  }
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (!props.isMenuOpen ? 'center' : 'start')};
  justify-content: ${(props) => (!props.isMenuOpen ? 'center' : 'start')};
`;

export const NavMenu = styled.div`
  width: ${(props) => (!props.isMenuOpen ? '50px' : '220px')};
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary};
  position: fixed;
  overflow: auto;
  height: 100%;
  transition: width 0.3s ease;
  top: 0;
  left: 0;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (!props.isMenuOpen ? 'none' : '8px')};
`;

export const Logo = styled.img`
  height: 44px;
  width: 117.08px;
`;
export const TicketHossLogo = styled.img`
  height: 70px;
  width: 1500.08px;
`;

export const NavItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (!props.isMenuOpen ? '0 5px 0 7px' : '0 5px 0 10px')};
  height: 40px;
  font-family: 'Barlow Condensed';
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  justify-content: space-between;

  color: #fff;
  margin-inline: 5px;
  width: ${(props) => (!props.isMenuOpen ? 'none' : '90%')};

  background-color: ${(props) =>
    props.isActive &&
    (!props.isMenuOpen || !props.active) &&
    props.theme.colors.secondary};
  border-radius: 8px;

  &.active {
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
  }

  &:active {
    color: #fff;
  }

  &:visited {
    color: #fff;
  }
`;

export const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (!props.isMenuOpen ? '0 7px' : '0 20px 0px 10px')};
  height: ${(props) => (!props.isMenuOpen ? '40px' : '45px')};
  width: ${(props) => (!props.isMenuOpen ? 'auto' : '80%')};
  font-family: 'Barlow Condensed';
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;

  color: #fff;
  margin-inline: 5px;
  gap: 15px;

  &.active {
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
  }

  &:active {
    color: #fff;
  }

  &:visited {
    color: #fff;
  }
`;

export const NavItemButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (!props.isMenuOpen ? '0 7px' : '0 20px 0px 10px')};
  height: ${(props) => (!props.isMenuOpen ? '40px' : '45px')};
  width: ${(props) => (!props.isMenuOpen ? 'auto' : '80%')};
  font-family: 'Barlow Condensed';
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;

  color: #fff;
  margin-inline: 5px;
  gap: 15px;

  &.active {
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
  }

  &:active {
    color: #fff;
  }

  &:visited {
    color: #fff;
  }
`;

export const NavSubItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  font-family: 'Barlow Condensed';
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  padding: 0 20px 0 35px;

  color: #fff;

  margin-right: 5px;
  margin-left: 20px;
  width: 60%;

  &.active {
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
  }

  &:active {
    color: #fff;
  }

  &:visited {
    color: #fff;
  }
`;

export const NavItemExternal = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (!props.isMenuOpen ? '0 7px' : '0 20px 0px 10px')};
  height: ${(props) => (!props.isMenuOpen ? '40px' : '45px')};
  width: ${(props) => (!props.isMenuOpen ? 'auto' : '80%')};
  font-family: 'Barlow Condensed';
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;

  color: #fff;
  margin-inline: 5px;
  gap: 15px;

  &.active {
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
  }

  &:active {
    color: #fff;
  }

  &:visited {
    color: #fff;
  }
`;

export const SettingsLink = styled(NavLink)`
  border-top-width: 3px;
  border-bottom-width: 3px;
  border-color: transparent;
  border-style: solid;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  align-items: center;

  &.active {
    border-bottom-color: ${(props) => props.theme.colors.primary};
  }

  &:active {
    color: #fff;
  }

  &:visited {
    color: #fff;
  }
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 1px;
  width: 100%;
`;

export const AccountsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  margin-bottom: 10px;
  width: 95%;
`;

export const SettingsWrapper = styled.div`
  padding: 0 5px 0 0;
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 13px;
  width: 15px;
  /* Circular border with a radius of 2 and text centered */
  border-radius: 50%;
  text-align: center;
  color: #fff;
  border: 2px solid #fff;
  padding: 2px;
  align-items: center;
  height: 15px;
  justify-content: center;
  display: flex;
  margin-top: 3px;
  margin-right: ${(props) => (props.isMobile ? '15px' : '0px')};
`;

export const UserRole = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  opacity: 0.7;
  text-transform: uppercase;
`;
