import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
import { useDuplicateEvent } from 'components/Events/gql';
import { PassesModal } from 'components/Participants/Modals';
import { SearchableListContainer } from 'components/SearchableListContainer';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import { useCart } from 'shared/CartContext';
import { CancelModal } from '../CancelModal';
import { EventHeader } from './components/EventHeader';
import { MenuCard } from './components/MenuCard';
import { RefundEventFlow } from './components/RefundEventFlow';
import { useGetEvent } from './gql/queries/get-event';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    margin: 0 10px;
    padding: 10px;
  }
`;

export const EventsMenu = () => {
  const { id } = useParams();
  const history = useHistory();
  const duplicateEvent = useDuplicateEvent();

  const { setSelectedSeatObject } = useCart();

  const [eventRefundIsVisible, setEventRefundIsVisible] = useState(false);

  const [currentEvent, setCurrentEvent] = useState(null);

  const openEventRefund = () => {
    setEventRefundIsVisible(true);
  };

  const closeEventRefund = () => {
    setEventRefundIsVisible(false);
  };

  const { data: eventData } = useGetEvent(parseInt(id));

  useEffect(() => {
    // Clear point of sale cart on mount
    localStorage.removeItem('tickets');
    localStorage.removeItem('selectedTicket');

    setSelectedSeatObject({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventData && eventData?.getEvent) {
      setCurrentEvent(eventData.getEvent);
    }
  }, [eventData]);

  const pathname = useLocation().pathname;
  const userType =
    ['admin-employee', 'admin-track'].find((type) => pathname.includes(type)) ||
    'admin';

  const {
    state: { capabilities },
  } = useContext(AppContext);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPasses, setShowPasses] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const openPermissionModal = () => {
    setShowPermissionModal(true);
  };

  const MenuList = [
    userType === 'admin' && {
      title: 'Edit Event',
      detail: 'Edit the event details, ticket types, and more.',
      url: `/admin/events/editEvent/${id}`,
      icon: 'Edit',
    },
    userType === 'admin' && {
      title: 'Duplicate Event',
      detail: 'Create a new event with the same details as this event.',
      onClick: async () => {
        if (window.confirm('Are you sure you want to duplicate this event?')) {
          const response = await duplicateEvent(+id, currentEvent?.track_id);
          if (!response || response.errors) {
            toast.error('Could not duplicate this event');
          } else {
            toast.success('Event successfully duplicated');
          }
        }
      },
      icon: 'Copy-Duplicate',
    },
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Scan Passes'
          : 'Scan Tickets',
      detail: 'Enter Scan Mode',
      url: `/${userType}/scan/${id}?date=${currentEvent?.start_date}`,
      onClick:
        userType === 'admin' || capabilities?.scan_tickets
          ? null
          : openPermissionModal,
      icon: 'Scan-Tickets',
    },
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Participants List'
          : 'Ticket Holders',
      detail:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Manage Event Participants'
          : 'Manage Event Ticket Holders',
      url:
        userType === 'admin'
          ? `/${userType}/series/${currentEvent?.track_id}/events/${id}/participants?date=${currentEvent?.start_date}`
          : `/${userType}/track/${currentEvent?.track_id}/events/${id}/participants?date=${currentEvent?.start_date}`,
      onClick:
        userType === 'admin' || capabilities?.events
          ? null
          : openPermissionModal,
      icon: 'Participant-Ticket-Holders-List',
    },
    {
      title: 'Point of Sale',
      detail: 'Enter POS Mode',
      url: `/${userType}/cart/${id}`,
      onClick:
        userType === 'admin' || capabilities?.pos ? null : openPermissionModal,
      icon: 'Point-Of-Sale',
    },
    {
      title: 'Cancel / Postpone',
      detail: 'Cancel or Postpone This Event',
      onClick:
        userType === 'admin' || capabilities?.cancel_event
          ? () => setShowCancelModal(true)
          : openPermissionModal,
      icon: 'Cancel-Postpone',
    },
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Passes on Sale'
          : 'Tickets on Sale',
      detail: `See What is On Sale.`,
      onClick:
        userType === 'admin' || capabilities?.events
          ? () => setShowPasses(true)
          : openPermissionModal,
      icon: 'Tickets-Passes-On-Sale',
    },
    userType !== 'admin' && {
      title: 'Weather Center',
      detail: 'View Event Weather',
      url: `/${userType}/events/weather/${id}`,
      icon: 'Weather',
    },
    userType !== 'admin' && {
      title: 'Guests List',
      detail: 'Manage Event Guest List',
      url: `/${userType}/guest-lists/event/${id}`,
      onClick:
        userType === 'admin' || capabilities?.guests
          ? null
          : openPermissionModal,
      icon: 'Guest-List',
    },
    currentEvent?.has_reserved_seats && {
      title: 'Reserved Seats',
      detail: 'Manage Reserved Charts',
      onClick:
        userType === 'admin' || capabilities?.events
          ? () =>
              history.push(`/${userType}/events/reservedSeats/${id}`, {
                event: currentEvent,
              })
          : openPermissionModal,
      icon: 'Seats',
    },
    {
      title: 'Reporting',
      detail: 'Generate Event Reports',
      onClick:
        userType === 'admin' ||
        (capabilities?.events && userType === 'admin') ||
        capabilities?.transactions
          ? () =>
              history.push(`/${userType}/events/reports/${id}`, {
                event: currentEvent,
              })
          : openPermissionModal,
      icon: 'Reports',
    },
    {
      title: 'Transactions',
      detail: 'View Event Transactions',
      url: `/${userType}/transactions/event/${id}`,
      onClick:
        userType === 'admin' || capabilities?.transactions
          ? null
          : openPermissionModal,
      icon: 'Transactions',
    },
    {
      title: 'Refund or Credit',
      detail: 'Issue Credits or Refunds',
      onClick: isMobile // Open refund modal on desktop
        ? null
        : userType === 'admin' ||
          capabilities?.issue_credits ||
          capabilities?.issue_refunds
        ? openEventRefund
        : openPermissionModal,
      // Open refund flow page on mobile
      url:
        isMobile &&
        (userType === 'admin' ||
          capabilities?.issue_credits ||
          capabilities?.issue_refunds)
          ? `/${userType}/events/refund/${id}`
          : null,
      icon: 'Refunds-Credits',
    },
    process.env.REACT_APP_PLATFORM === 'pitpay' && {
      title: 'Registrations',
      detail: 'View and manage registrations for this event.',
      url: `/${userType}/registrations/event/${id}`,
      onClick:
        userType === 'admin' || capabilities?.registrations
          ? null
          : openPermissionModal,
      icon: 'Registrations',
    },
  ];

  const MenuContainer = (
    <Container>
      {MenuList.filter(Boolean).map((menu, index) => (
        <MenuCard key={index} {...menu} />
      ))}
      {showCancelModal && (
        <CancelModal
          close={() => setShowCancelModal(false)}
          selectedEvent={currentEvent}
          objectId={id}
          noDelete={userType !== 'admin'}
        />
      )}
    </Container>
  );

  return (
    <>
      {isMobile ? (
        <>
          <EventHeaderMobile queryDate={currentEvent?.date} noDropdown />
          {MenuContainer}
        </>
      ) : (
        <SearchableListContainer
          header={currentEvent && <EventHeader event={currentEvent} />}
          noSearch
          noContainerHeader
          title="Event Menu"
        >
          {MenuContainer}
        </SearchableListContainer>
      )}

      <RefundEventFlow
        isVisible={eventRefundIsVisible}
        close={closeEventRefund}
        event={currentEvent}
      />

      <PassesModal
        showModal={showPasses}
        event={currentEvent}
        close={() => {
          setShowPasses(false);
        }}
      />

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/${userType}/settings/1`}
        history={history}
      />
    </>
  );
};
