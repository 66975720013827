import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
import { useGetEventReportOptions } from 'components/Participants/gql/useGetEventReportOptions';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { EventHeader } from '../EventsMenu/components/EventHeader';
import { MenuCard } from '../EventsMenu/components/MenuCard';
import { PrintEventPerformanceReport } from './Reports/EventPerformanceReport';

const Container = styled.div`
  // Grid display with a maximum of 4 columns
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center;

  // Responsive design
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    margin: 0 10px 10px 10px;
    padding: 20px;
  }
`;

export const EventsReport = () => {
  // Get id from the URL
  const { id } = useParams();

  const history = useHistory();

  const location = useLocation();
  const { event } = location.state || {};

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : pathname.split('/').includes('admin-track')
    ? 'track'
    : 'admin';

  // Fetch event spectator report options available and display them
  const { data } = useGetEventReportOptions();
  const eventPrintReportOptions = data?.getEventPrintReportOptions;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const MenuList = [
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Participants Report'
          : 'Ticket Holder Report',
      detail:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Report containing a list of all Participants. Report contains purchaser, ticket #, etc.'
          : 'Report containing a list of all spectators. Report contains purchaser, ticket #, etc.',
      onClick: () =>
        userType === 'admin'
          ? history.push(
              `/${userType}/events/reports/spectatorsList/${id}?date=${event.start_date}`,
              {
                event: event,
              }
            )
          : history.push(
              `/admin-${userType}/events/reports/spectatorsList/${id}?date=${event.start_date}`,
              {
                event: event,
              }
            ),
      icon: 'Reports',
    },
    // {
    //   title: 'Specators List CSV',
    //   detail:
    //     'CSV report containing a list of all spectators. Report contains purchaser, ticket #, etc.',
    //   component: <Export event={event} date={event?.start_date} />,
    // },
    {
      title: 'Transaction Report',
      detail:
        'Comprehensive list of all transactions for this event, regardless of their order source.',
      onClick: () =>
        userType === 'admin'
          ? history.push(`/${userType}/transactions/event/${id}`, {
              event: event,
            })
          : history.push(`/admin-${userType}/transactions/event/${id}`, {
              event: event,
            }),
      icon: 'Reports',
    },
    {
      title: 'Event Performance Report',
      detail:
        'This report contains a complete snapshot of total event sales as well as your final event payout.',
      icon: 'Reports',
      component: <PrintEventPerformanceReport eventId={id} />,
    },
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Pass Breakdown Report'
          : 'Ticket Breakdown Report',
      detail:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Report breaks down how many of each pass were sold.'
          : 'Report breaks down how many of each ticket were sold.',
      onClick: () =>
        userType === 'admin'
          ? history.push(`/${userType}/events/reports/ticketBreakdown/${id}`, {
              event: event,
            })
          : history.push(
              `/admin-${userType}/events/reports/ticketBreakdown/${id}`,
              {
                event: event,
              }
            ),
      icon: 'Reports',
    },
    {
      title: 'App Transactions Report',
      detail: 'Detailed report for all transactions generated in the App.',
      url:
        userType === 'admin'
          ? `/${userType}/events/reports/appReport/${id}`
          : `/admin-${userType}/events/reports/appReport/${id}`,
      icon: 'Reports',
    },
    {
      title: 'POS Cash Transactions Report',
      detail:
        'Reports all cash transactions generated by the Point of Sale system.',
      url:
        userType === 'admin'
          ? `/${userType}/events/reports/posCashReport/${id}`
          : `/admin-${userType}/events/reports/posCashReport/${id}`,
      icon: 'Reports',
    },
    {
      title: 'POS Card Transactions Report',
      detail:
        'Reports all card transactions generated by the Point of Sale system.',
      url:
        userType === 'admin'
          ? `/${userType}/events/reports/posCardReport/${id}`
          : `/admin-${userType}/events/reports/posCardReport/${id}`,
      icon: 'Reports',
    },
    // Add lap report options if available
    ...(eventPrintReportOptions?.length
      ? eventPrintReportOptions
          .filter((option) => option.id !== 0)
          .map((option) => ({
            // Check and remove the word 'Print' from the title
            title:
              option?.name && option?.name.includes('Print')
                ? option?.name.replace('Print', '')
                : option?.name,
            detail: option?.description,
            onClick: () =>
              userType === 'admin'
                ? history.push(
                    `/${userType}/events/reports/lapSponsorshipReport/${option.id}`,
                    {
                      lapSponsorship: option,
                      event: event,
                    }
                  )
                : history.push(
                    `/admin-${userType}/events/reports/lapSponsorshipReport/${option?.id}`,
                    {
                      lapSponsorship: option,
                      event: event,
                    }
                  ),
            icon: 'Reports',
          }))
      : []),
  ];

  const MenuContainer = (
    <Container>
      {MenuList.map((menu, index) => (
        <MenuCard key={index} {...menu} />
      ))}
    </Container>
  );

  return (
    <>
      {isMobile ? (
        <>
          <EventHeaderMobile queryDate={event?.date} noDropdown />
          {MenuContainer}
        </>
      ) : (
        <SearchableListContainer
          header={event ? <EventHeader event={event} /> : undefined}
          title="Reports"
          noSearch
        >
          {MenuContainer}
        </SearchableListContainer>
      )}
    </>
  );
};
