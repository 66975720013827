import React, { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  TransactionDateFilter,
  withTransactionDateFilterContext,
} from 'components/Transactions/TransactionDateFilter';
import { formatTimestamp } from 'shared/formatters';
import MobileSearchInput from '../../../../track/GuestList/GuestListEventsMobile/search';
import ExportAnnualPurchase from '../ExportAnnualPurchase';
import { useGetAnnualPurchaseList } from '../gql/useGetAnnualPurchaseList';
import { PrintAnnualPurchaseReport } from '../PrintAnnualPurchaseReport';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
`;

const PurchaseListMobile = withTransactionDateFilterContext((props) => {
  const [search, setSearch] = useState('');

  const { location } = props;

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const name = urlParams.get('ticketName');

  const { id } = useParams();

  const { data } = useGetAnnualPurchaseList();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  if (!data?.getAnnualPurchaseList) return null;

  return (
    <>
      <div style={{ justifyContent: 'center', alignContent: 'center' }}>
        <ReportContainer>
          <ExportAnnualPurchase />
          <PrintAnnualPurchaseReport
            ticket_id={id}
            annualTicketName={name}
            color={'white'}
          />
        </ReportContainer>

        <MobileSearchInput
          placeholder="Search Purchase"
          onChange={handleChange}
          value={search}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKeyPress={handleKeyPress}
        />
        <Spacer size={10} />

        <div
          style={{
            margin: 'auto',
          }}
        >
          <TransactionDateFilter mobilePicker={true} />
        </div>

        <Spacer size={20} />
        {data.getAnnualPurchaseList.results.map((purchase) => (
          <MobileContainer>
            <FlexRow>
              <CardInfo>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    type="heading"
                    fontSize={25}
                    lineHeight={28}
                    color="#fff"
                  >
                    {formatTimestamp(
                      Number(purchase.purchase_date),
                      'MMM DD - YYYY h:mm A',
                      true
                    ).toUpperCase()}
                  </Text>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}
                >
                  <Text
                    type="heading"
                    fontSize={25}
                    lineHeight={28}
                    color="#fff"
                  >
                    {purchase.user ? `${purchase.user.formatted_name}` : ''}
                  </Text>
                </div>
              </CardInfo>
            </FlexRow>
          </MobileContainer>
        ))}
        <div
          style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}
        >
          <Pagination
            count={data.getAnnualPurchaseList.count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
            color={'#fff'}
          />
        </div>
        <Spacer size={40} />
      </div>
    </>
  );
});

export default PurchaseListMobile;
