/* eslint-disable no-unused-vars */
import qs from 'qs';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, IconButton } from 'components/Button';
import { HideOnMobile, ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { DateCard } from 'components/DateCard';
import { SearchInput } from 'components/Form/SearchInput';
import { HelpModals } from 'components/HelpModals';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { CheckInModal } from 'components/Modal';
import Pagination from 'components/Pagination';
import {
  useCheckInUser,
  useGetTicketsList,
  useUncheckInUser,
} from 'components/Participants/gql';
import { CancelModal, PassesModal } from 'components/Participants/Modals';
import Spacer from 'components/Spacer';
import { Table, TableRow } from 'components/Table';
import Text from 'components/Text';
import { useDebounceCallback } from 'hooks/useDebounceCallback';
import useNewTicket from 'hooks/useNewTicket';
import useTheme from 'hooks/useTheme';
import { is_cellphone } from 'pages/app/Users';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import { formatPhoneNumber } from 'shared/formatters';
import DateDropdown from './dateDropdown';
import HeaderButtons from './HeaderButtons';

const TitleContainer = styled.div`
  @media (min-width: 860px) {
    display: flex;
    flex-direction: row;
  }
`;

export const NameLink = styled(Link)`
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: ${(props) => props.theme.colors.primary};
  border: none;
  font-size: 16px;
  font-family: Roboto;
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;
const HeaderWrapper = styled.div`
  @media (min-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 40px 20px 40px;
  }
`;

const Header = styled.div`
  width: 80vw;
`;

const Tr = styled(TableRow)`
  & > :first-child {
    padding-left: 20px;
  }
  & > :last-child {
    padding-right: 20px;
  }
`;

const PassesButton = styled.button`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:focus {
    outline: 0;
  }
`;

const IconWrapper = styled.span`
  transform: rotate(-90deg);
  display: block;
  margin-left: 10px;
`;

const Tickets = (props) => {
  const { location, match } = props;
  const { date } = qs.parse(location.search.substring(1));
  const [search, setSearch] = useState('');

  const urlParams = new URLSearchParams(location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const { data, subscribeToMore } = useGetTicketsList({
    date,
    search,
    page: currentPage ? String(currentPage) : '1',
  });

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const userType = props.pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const {
    state: { capabilities },
  } = useContext(AppContext);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSelectedEvent, setShowSelectedEvent] = useState({});
  const [showPasses, setShowPasses] = useState(false);
  const theme = useTheme();

  const eventId = parseInt(match.params.id, 10);
  useNewTicket({ subscribeToMore }, eventId, search, date);
  const checkInUser = useCheckInUser();
  const uncheckInUser = useUncheckInUser();
  const [storedCheckins, setStoredCheckins] = useState([]);
  const [storedUnChecks, setStoredUnChecks] = useState([]);
  const [unCheck, setUncheck] = useState(false);
  const [purchaseItem, setPurchaseItem] = useState(false);

  const [shouldDisplayCheckInModal, setShouldDisplayCheckInModal] =
    useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const selectOne = async () => {
    // check if it's already selected
    if (purchaseItem.is_checked) {
      setStoredUnChecks((prev) => [...new Set(prev.concat(purchaseItem.id))]);
    } else {
      setStoredCheckins((prev) => [...new Set(prev.concat(purchaseItem.id))]);
    }

    return purchaseItem.is_checked
      ? await uncheckInUser([purchaseItem.id])
      : await checkInUser([purchaseItem.id]);
  };

  const adminTrack = location.pathname.indexOf('/admin-track/');

  const debouncedInputChange = useCallback(
    useDebounceCallback((value) => {
      setSearch(value);
    }, 1000),
    []
  );

  const handleChange = (event) => {
    const targetValue = event.target.value;

    debouncedInputChange(targetValue);
  };

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  const Tickets = data?.getSpectatorPurchases?.results;
  const ticketCount = data?.getSpectatorPurchases?.count;
  const totalCount = data?.getSpectatorCount;
  const event = data?.getEvent;

  useEffect(() => {
    if (!event) return;

    setShowSelectedEvent({ ...event });
  }, [event]);

  const tableColumns = [
    {
      label: 'Purchaser',
      key: 'user_name',
    },
    {
      label: 'Ticket Count',
      key: 'ticket_count',
    },
  ];
  function renderRows(participant, index) {
    const { user, ticket_count } = participant;

    return {
      rowProps: {},

      user_name: (
        <NameLink
          highlight={'orange'}
          to={(location) => ({
            ...location,
            pathname: `${location.pathname}/${participant.id}`,
          })}
        >
          {is_cellphone(user.formatted_name)
            ? formatPhoneNumber(user.formatted_name)
            : `${user.formatted_name}`.toUpperCase()}
        </NameLink>
      ),
      ticket_count: ticket_count,
    };
  }

  return !event ? (
    <Loading size={60} />
  ) : (
    <>
      <HeaderWrapper>
        <Header>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DateCard item={event} margin={'margin: 0 10px 0 0'} />

            <Text type="heading" fontSize={32} color={theme.colors.secondary}>
              {event.name}
            </Text>

            {'postponed' === event.status ? (
              <img
                style={{
                  width: '10%',
                  height: 'auto',
                  display: 'block',
                  marginLeft: 20,
                  transform: 'rotate(10deg)',
                }}
                src="https://d3294qt0f4hbwl.cloudfront.net/postponed.png"
                alt="postponed-stamp"
              />
            ) : null}
            {'cancelled' === event.status ? (
              <img
                style={{
                  width: '10%',
                  height: 'auto',
                  display: 'block',
                  marginLeft: 20,
                }}
                src="https://d3294qt0f4hbwl.cloudfront.net/cancelled.png"
                alt="cancelled-stamp"
              />
            ) : null}
          </div>
        </Header>
        <HeaderButtons
          event={event}
          date={date}
          match={match}
          setShowCancelModal={setShowCancelModal}
        />
        <div style={{ width: '20%', marginLeft: 40 }}>
          <img
            style={{ width: '100%', height: 'auto', display: 'block' }}
            src={event.track?.logo ?? event.series?.logo}
            alt="logo"
          />
        </div>
      </HeaderWrapper>{' '}
      {!Tickets || !event ? null : (
        <Container>
          <ContainerHeader>
            <ContainerWrapper>
              <TitleContainer>
                <Text
                  type="heading"
                  color="#3C4144"
                  inlineStyle={{ marginRight: 20, marginTop: 10 }}
                >
                  Tickets Sold ({totalCount})
                </Text>

                {event.isMultiDay && <DateDropdown />}

                <SearchInput
                  placeholder="Search Customer Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                  style={{ width: 230 }}
                />

                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginLeft: 15,
                  }}
                >
                  <Icon
                    style={{ marginTop: 7 }}
                    icon="Dashboard-Help-Icon"
                    onClick={() => setShowHelpModal(true)}
                    size={35}
                    color={theme.colors.primary}
                  />
                </div>
              </TitleContainer>

              <PassesButton
                onClick={() => {
                  setShowPasses(true);
                }}
              >
                <Text
                  type="label"
                  fontSize={18}
                  color={theme.colors.secondary}
                  inlineStyle={{ cursor: 'pointer' }}
                >
                  {process.env.REACT_APP_PLATFORM !== 'tickethoss'
                    ? 'Passes on Sale'
                    : 'Tickets on Sale'}
                </Text>
                <IconWrapper>
                  <Icon icon="chevron" size={24} color={theme.colors.primary} />
                </IconWrapper>
              </PassesButton>
            </ContainerWrapper>
          </ContainerHeader>
          <div style={{ padding: '20px 0' }}>
            <HideOnMobile>
              <Table
                items={Tickets ?? []}
                columns={tableColumns}
                Components={{ Row: Tr }}
                renderRows={renderRows}
                noData={
                  <p
                    style={{
                      color: '#000',
                      fontSize: 20,
                      fontFamily: 'Barlow Condensed',
                      fontWeight: 600,
                      padding: 20,
                    }}
                  >
                    The event does not have any Tickets yet
                  </p>
                }
              />

              <Pagination
                count={ticketCount}
                perPage={15}
                currentPage={currentPage || 1}
              />

              {showCancelModal && (
                <CancelModal
                  adminTrack={adminTrack}
                  showCancelModal={showCancelModal}
                  close={() => {
                    setShowCancelModal(false);
                  }}
                  selectedEvent={showSelectedEvent}
                  currentType={match.url.includes('track') ? 'track' : 'series'}
                  objectId={match.params.id}
                />
              )}

              {showPasses && (
                <PassesModal
                  showModal={showPasses}
                  event={event}
                  close={() => {
                    setShowPasses(false);
                  }}
                />
              )}

              <CheckInModal
                itemType={
                  process.env.REACT_APP_PLATFORM !== 'tickethoss'
                    ? 'Pass'
                    : 'Ticket'
                }
                isVisible={!!shouldDisplayCheckInModal}
                onConfirm={selectOne}
                setIsVisible={setShouldDisplayCheckInModal}
                unCheck={unCheck}
              />
            </HideOnMobile>
          </div>
          {/*  {ticket ? (
            <TicketModal
              location={location}
              admin={location.pathname.includes('/admin/transactions/')}
              match={match}
              ticket={ticket}
              close={() => {
                const srch = search;
                setSearch('a');
                setTicket(null);

                setSearch(srch);
              }}
            />
          ) : null} */}
          <PermissionModal
            isVisible={showPermissionModal}
            hideModal={() => setShowPermissionModal(false)}
            link={`/admin-${userType}/settings/1`}
            history={props.history}
          />
          <HelpModals
            isVisible={showHelpModal}
            setIsVisible={setShowHelpModal}
            contents={[
              {
                title: 'Explanation Part 1',
                content:
                  'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
                video: 'https://player.vimeo.com/video/903075544?h=a35101967c',
              },
              {
                title: 'Explanation Part 2',
                content: (
                  <div>
                    <p>
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups.
                    </p>
                    <Spacer size={16} />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </p>
                  </div>
                ),
              },
              {
                title: 'Explanation Part 3',
                content: (
                  <span>
                    Lorem ipsum is placeholder text commonly used in the
                    graphic, print, and publishing industries for previewing
                    layouts and visual mockups.
                  </span>
                ),
              },
              {
                title: 'Hide Help Like This?',
                titleStyle: {
                  fontSize: 32,
                  fontWeight: 700,
                  lineHeight: '40px',
                  fontFamily: 'Barlow Condensed',
                },
                content: (
                  <div style={{ alignContent: 'center', textAlign: 'center' }}>
                    <p style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                      Click on any{' '}
                      <Icon
                        icon="Dashboard-Help-Icon"
                        size={18}
                        color={theme.colors.primary}
                      />{' '}
                      icon to bring it back.
                    </p>

                    <Spacer size={20} />
                    <Button onClick={() => setShowHelpModal(false)}>
                      {' '}
                      Hide For Today{' '}
                    </Button>

                    <Spacer size={10} />
                    <Button
                      onClick={() => setShowHelpModal(false)}
                      outlined={true}
                    >
                      {' '}
                      Hide Forever
                    </Button>

                    <Spacer size={10} />
                    <Button
                      onClick={() => setShowHelpModal(false)}
                      outlined={true}
                      buttonStyle={{ border: 'none' }}
                    >
                      {' '}
                      Hide Now
                    </Button>
                  </div>
                ),
                hideFooter: true,
                customStyle: {
                  justifyContent: 'center',
                  margin: 'auto',
                  textAlign: 'center',
                },
              },
            ]}
          />
        </Container>
      )}
    </>
  );
};

export default Tickets;
