import React, { useEffect, useState } from 'react';
import { Col } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import { Checkbox } from 'components/Form/Checkbox';
import { DatePicker } from 'components/Form/DatePicker';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { ResponsiveCol, ResponsiveRow } from '../AddEvents/styles';
import { DESCRIPTION_CHARACTER_LIMIT } from '../EventEdit';
import { TieredPricing } from './Components/TieredPricing';

const InputWrapper = styled.div`
  margin-top: 10px;
`;

const CharacterCount = styled.span`
  color: ${(props) => (props.overLimit ? props.theme.colors.error : 'unset')};
  margin-bottom: 6px;
`;

const VisibilityOptions = [
  { value: 'visible', label: 'Visible' },
  { value: 'hidden', label: 'Hidden' },
];

export const OtherTickets = ({
  handleChange,
  COLOR_CODES,
  ticket,
  index,
  ticketType,
  errors,
  allCategories,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [characterCount, setCharacterCount] = useState(0);

  const [specialTicket, setSpecialTicket] = useState(false);
  const [ticketLimit, setTicketLimit] = useState(false);
  const [highlightedTicket, setHighlightedTicket] = useState(false);
  const [lowTicketAlert, setLowTicketAlert] = useState(false);
  const [reservedSeat, setReservedSeat] = useState(false);
  const [salesWindow, setSalesWindow] = useState(false);
  const [ticketTiers, setTicketTiers] = useState(false);
  const [access_code, setAccessCode] = useState(false);

  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (ticket.fee_dollar || ticket.fee_percent) {
      setSpecialTicket(true);
      handleChange({
        target: {
          name: `special_ticket`,
          value: true,
        },
      });
    }
    if (ticket.is_featured) setHighlightedTicket(true);
    if (ticket.limit) setTicketLimit(true);
    if (ticket.reserved_seat) setReservedSeat(true);
    if (ticket.sale_start || ticket.sale_end) setSalesWindow(true);
    if (ticket.ticket_tiers && ticket.ticket_tiers.length > 0) {
      setTicketTiers(true);
    }
    if (ticket.code || ticket.code_date || ticket.code_time) {
      setAccessCode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ticket.fee_dollar,
    ticket.fee_percent,
    ticket.is_featured,
    ticket.limit,
    ticket.reserved_seat,
  ]);

  return (
    <>
      <ResponsiveRow style={{ alignItems: 'center' }}>
        <Text
          style={{ marginRight: '15px' }}
          type="label"
          color={theme.colors.black}
          inlineStyle={{ textTransform: 'capitalize' }}
        >
          Other ticket
        </Text>
        <ResponsiveCol>
          <DatePicker
            name={`${ticketType}.${index}.start_date`}
            error={
              errors[`${ticketType}.${index}.start_date`]
                ? errors[`${ticketType}.${index}.start_date`]
                : ''
            }
            type="date"
            label=""
            value={ticket.start_date}
            onChange={(_, value) => {
              handleChange({ target: { name: 'start_date', value } });
              errors[`${ticketType}.${index}.start_date`] = '';
            }}
            isResponsive={true}
          />
        </ResponsiveCol>
        <ResponsiveCol>
          <DatePicker
            name={`${ticketType}.${index}.end_date`}
            error={
              errors[`${ticketType}.${index}.end_date`]
                ? errors[`${ticketType}.${index}.end_date`]
                : ''
            }
            type="date"
            label=""
            value={ticket.end_date}
            onChange={(_, value) => {
              handleChange({ target: { name: 'end_date', value } });
              errors[`${ticketType}.${index}.end_date`] = '';
            }}
            isResponsive={true}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 70%' : '0 0 100%' }}>
          <Input
            id={`${ticketType}.${index}.name`}
            name={`${ticketType}.${index}.name`}
            error={
              errors[`${ticketType}.${index}.name`]
                ? errors[`${ticketType}.${index}.name`]
                : ''
            }
            label="Ticket Name"
            placeholder=""
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `name`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.name`] = '';
            }}
            value={ticket.name ?? ''}
          />
        </ResponsiveCol>
        <ResponsiveCol>
          <Input
            id={`${ticketType}.${index}.price`}
            name={`${ticketType}.${index}.price`}
            error={
              errors[`${ticketType}.${index}.price`]
                ? errors[`${ticketType}.${index}.price`]
                : ''
            }
            label="Ticket Price"
            type="number"
            placeholder=""
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `price`,
                  value: target.value ? parseFloat(target.value) : '',
                },
              });
              errors[`${ticketType}.${index}.price`] = '';
            }}
            value={ticket.price ?? ''}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <ResponsiveRow style={{ display: 'flex' }}>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 70%' : '0 0 100%' }}>
          <Input
            as="textarea"
            labelRight={
              <CharacterCount
                overLimit={characterCount > DESCRIPTION_CHARACTER_LIMIT}
              >
                {characterCount} characters
              </CharacterCount>
            }
            rows={6}
            inputStyle={{ minHeight: 'unset' }}
            id={`${ticketType}.${index}.description`}
            name={`${ticketType}.${index}.description`}
            label="Description"
            placeholder=""
            onChange={({ target }) => {
              typeof target.value === 'string' &&
                debounce(300, setCharacterCount(target.value.length));
              handleChange({
                target: {
                  name: `description`,
                  value: target.value,
                },
              });
            }}
            value={ticket.description ?? ''}
          />

          <ResponsiveRow>
            <div
              style={{
                paddingBottom: 10,
                paddingLeft: 10,
                margin: '10px 0px',
                marginLeft: 5,
                cursor: 'pointer',
                color: `${theme.colors.primary}`,
                display: 'flex',
                flexDirection: 'row',
              }}
              onClick={() => setViewMore(!viewMore)}
              role="presentation"
            >
              {viewMore ? 'View Less' : 'View More'}
              <div
                style={{
                  marginLeft: 5,
                  transform: `rotate(${viewMore ? -180 : 0}deg)`,
                  transition: 'transform 0.5s ease',
                }}
              >
                <Icon icon="chevron" size={14} color={theme.colors.primary} />
              </div>
            </div>
          </ResponsiveRow>
          {viewMore && (
            <>
              <ResponsiveRow>
                <ResponsiveCol>
                  <Checkbox
                    myStyle={{ marginBottom: '30px', display: 'inline-block' }}
                    name="special_ticket_fee"
                    className="special_ticket_fee"
                    checked={specialTicket}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      setSpecialTicket(value);
                      handleChange({
                        target: {
                          name: `special_ticket`,
                          value: value,
                        },
                      });
                    }}
                    rightText="Special Ticket Fee"
                  />
                </ResponsiveCol>
              </ResponsiveRow>
              <ResponsiveRow>
                {specialTicket && (
                  <>
                    <ResponsiveCol>
                      <InputWrapper>
                        <Input
                          style={{ marginRight: '15px' }}
                          id={`${ticketType}.${index}.fee_dollar`}
                          name={`${ticketType}.${index}.fee_dollar`}
                          label="Dollar Amount (App)"
                          type="number"
                          placeholder=""
                          onChange={({ target }) =>
                            handleChange({
                              target: {
                                name: `fee_dollar`,
                                value: parseFloat(target.value),
                              },
                            })
                          }
                          value={ticket.fee_dollar ?? ''}
                        />
                      </InputWrapper>
                    </ResponsiveCol>
                    <ResponsiveCol>
                      <InputWrapper>
                        <Input
                          style={{ marginRight: '15px' }}
                          id={`${ticketType}.${index}.fee_percent`}
                          name={`${ticketType}.${index}.fee_percent`}
                          label="Fee Percent (App)"
                          type="number"
                          placeholder=""
                          onChange={({ target }) =>
                            handleChange({
                              target: {
                                name: `fee_percent`,
                                value: parseFloat(target.value),
                              },
                            })
                          }
                          value={ticket.fee_percent ?? ''}
                        />
                      </InputWrapper>
                    </ResponsiveCol>
                  </>
                )}
              </ResponsiveRow>
              <ResponsiveRow>
                {specialTicket && (
                  <>
                    <ResponsiveCol>
                      <InputWrapper>
                        <Input
                          style={{ marginRight: '15px' }}
                          id={`${ticketType}.${index}.pos_fee_dollar`}
                          name={`${ticketType}.${index}.pos_fee_dollar`}
                          label="Dollar Amount (POS)"
                          type="number"
                          placeholder=""
                          onChange={({ target }) =>
                            handleChange({
                              target: {
                                name: `pos_fee_dollar`,
                                value: parseFloat(target.value),
                              },
                            })
                          }
                          value={ticket.pos_fee_dollar ?? ''}
                        />
                      </InputWrapper>
                    </ResponsiveCol>
                    <ResponsiveCol>
                      <InputWrapper>
                        <Input
                          style={{ marginRight: '15px' }}
                          id={`${ticketType}.${index}.pos_fee_percent`}
                          name={`${ticketType}.${index}.pos_fee_percent`}
                          label="Fee Percent (POS)"
                          type="number"
                          placeholder=""
                          onChange={({ target }) =>
                            handleChange({
                              target: {
                                name: `pos_fee_percent`,
                                value: parseFloat(target.value),
                              },
                            })
                          }
                          value={ticket.pos_fee_percent ?? ''}
                        />
                      </InputWrapper>
                    </ResponsiveCol>
                  </>
                )}
              </ResponsiveRow>

              <Spacer size={10} />

              <ResponsiveRow>
                <ResponsiveCol>
                  <Checkbox
                    myStyle={{ marginBottom: '30px', display: 'inline-block' }}
                    name="is_featured"
                    className="highlighted"
                    checked={highlightedTicket}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      setHighlightedTicket(value);
                      handleChange({
                        target: {
                          name: 'is_featured',
                          value,
                        },
                      });
                    }}
                    rightText="Display Highlighted Ticket"
                  />
                </ResponsiveCol>
                {highlightedTicket && (
                  <>
                    <ResponsiveCol>
                      <InputWrapper>
                        <Input
                          id={`${ticketType}.${index}.featured_text`}
                          name={`${ticketType}.${index}.featured_text`}
                          label="Highlighted Ticket Name"
                          type="text"
                          placeholder=""
                          onChange={({ target }) =>
                            handleChange({
                              target: {
                                name: `featured_text`,
                                value: target.value,
                              },
                            })
                          }
                          value={ticket.featured_text ?? ''}
                        />
                      </InputWrapper>
                    </ResponsiveCol>
                    <Col></Col>
                  </>
                )}
              </ResponsiveRow>
              {/* <div style={{display:'flex', alignItems:'center'}}> */}
              <ResponsiveRow>
                <ResponsiveCol>
                  <Checkbox
                    myStyle={{
                      marginBottom: '20px',
                      marginRight: '15px',
                      display: 'inline-block',
                    }}
                    name="ticket_limit"
                    className="ticket_limit"
                    checked={ticketLimit}
                    onChange={(event) => {
                      const value = event.target?.checked;

                      setTicketLimit(value);
                      if (!value)
                        handleChange({
                          target: {
                            name: `limit`,
                            value: null,
                          },
                        });
                    }}
                    rightText="Ticket Limit"
                  />
                </ResponsiveCol>
                <ResponsiveCol>
                  {ticketLimit && (
                    <InputWrapper>
                      <Input
                        style={{ marginRight: '15px' }}
                        id={`${ticketType}.${index}.limit`}
                        name={`${ticketType}.${index}.limit`}
                        label="Limit"
                        type="text"
                        placeholder=""
                        onChange={({ target }) =>
                          handleChange({
                            target: {
                              name: `limit`,
                              value: parseInt(target.value),
                            },
                          })
                        }
                        value={ticket.limit ?? ''}
                      />
                    </InputWrapper>
                  )}
                </ResponsiveCol>
                <Col></Col>
              </ResponsiveRow>
              <ResponsiveRow>
                <ResponsiveCol>
                  <Checkbox
                    myStyle={{
                      marginBottom: '20px',
                      marginRight: '15px',
                      display: 'inline-block',
                    }}
                    name="low_ticket_alert"
                    className="low_ticket_alert"
                    checked={lowTicketAlert}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      setLowTicketAlert(value);
                      handleChange({
                        target: {
                          name: 'low_ticket_alert',
                          value,
                        },
                      });
                    }}
                    rightText="Low Ticket Alert"
                  />
                </ResponsiveCol>
                <Col></Col>
              </ResponsiveRow>
              <TieredPricing
                ticketTiers={ticketTiers}
                setTicketTiers={setTicketTiers}
                handleChange={handleChange}
                ticket_tiers={
                  ticket.ticket_tiers?.length > 0
                    ? ticket.ticket_tiers
                    : [
                        {
                          label: '',
                          price: '',
                          track_price: '',
                        },
                      ]
                }
              />
              <ResponsiveRow>
                <ResponsiveCol>
                  <Checkbox
                    myStyle={{
                      marginBottom: '20px',
                      marginRight: '15px',
                      display: 'inline-block',
                    }}
                    name="reserved_seat"
                    className="reserved_seat"
                    checked={reservedSeat}
                    onChange={(event) => {
                      const value = event.target?.checked;
                      setReservedSeat(value);
                      handleChange({
                        target: {
                          name: 'reserved_seat',
                          value,
                        },
                      });
                    }}
                    rightText="Reserved Seat"
                  />
                </ResponsiveCol>
                <Col></Col>
              </ResponsiveRow>

              {reservedSeat && (
                <>
                  <ResponsiveRow>
                    <ResponsiveCol>
                      <InputWrapper>
                        <Input
                          style={{ marginRight: '15px' }}
                          id={`${ticketType}.${index}.reserved_seat_category`}
                          name={`${ticketType}.${index}.reserved_seat_category`}
                          label="Seats.io Category"
                          type="text"
                          placeholder=""
                          onChange={({ target }) =>
                            handleChange({
                              target: {
                                name: `reserved_seat_category`,
                                value: target.value,
                              },
                            })
                          }
                          value={ticket.reserved_seat_category ?? ''}
                        />
                      </InputWrapper>
                    </ResponsiveCol>
                    <ResponsiveCol>
                      <InputWrapper>
                        <Input
                          style={{ marginRight: '15px' }}
                          id={`${ticketType}.${index}.reserved_seat_prefix`}
                          name={`${ticketType}.${index}.reserved_seat_prefix`}
                          label="Ticket Prefix"
                          type="text"
                          placeholder=""
                          onChange={({ target }) =>
                            handleChange({
                              target: {
                                name: `reserved_seat_prefix`,
                                value: target.value,
                              },
                            })
                          }
                          value={ticket.reserved_seat_prefix ?? ''}
                        />
                      </InputWrapper>
                    </ResponsiveCol>
                  </ResponsiveRow>
                  <Spacer size={10} />
                  <ResponsiveRow>
                    <ResponsiveCol>
                      <Checkbox
                        myStyle={{
                          marginBottom: '20px',
                          marginRight: '15px',
                          display: 'inline-block',
                        }}
                        name="reserved_seat_section"
                        className="reserved_seat_section"
                        checked={ticket.reserved_seat_section}
                        onChange={(event) => {
                          const value = event.target?.checked;
                          handleChange({
                            target: {
                              name: 'reserved_seat_section',
                              value,
                            },
                          });
                        }}
                        rightText="Show as Section/Row/Seat"
                      />
                    </ResponsiveCol>
                  </ResponsiveRow>
                </>
              )}
              <Spacer size={10} />
              <ResponsiveRow>
                <ResponsiveCol>
                  {reservedSeat && (
                    <InputWrapper>
                      <Input
                        style={{ marginRight: '15px', marginLeft: '40px' }}
                        id={`${ticketType}.${index}.reserved_seat_category`}
                        name={`${ticketType}.${index}.reserved_seat_category`}
                        label="Seats.io Category"
                        type="text"
                        placeholder=""
                        onChange={({ target }) =>
                          handleChange({
                            target: {
                              name: `reserved_seat_category`,
                              value: target.value,
                            },
                          })
                        }
                        value={ticket.reserved_seat_category ?? ''}
                      />
                    </InputWrapper>
                  )}
                  <Col></Col>
                </ResponsiveCol>
              </ResponsiveRow>
              <ResponsiveRow>
                <ResponsiveCol>
                  <Checkbox
                    myStyle={{
                      marginBottom: '20px',
                      marginRight: '15px',
                      display: 'inline-block',
                    }}
                    name="sales_window"
                    className="sales_window"
                    checked={salesWindow}
                    onChange={(event) => {
                      const value = event.target?.checked;

                      setSalesWindow(value);
                      // if (!value)
                      //   handleChange({
                      //     target: {
                      //       name: `limit`,
                      //       value: null,
                      //     },
                      //   });
                    }}
                    rightText="Sales Window"
                  />
                </ResponsiveCol>
              </ResponsiveRow>
              {salesWindow && (
                <>
                  <ResponsiveRow>
                    <ResponsiveCol>
                      <DatePicker
                        id="sale_start"
                        name="sale_start"
                        label="Ticket Sales Start"
                        type="date"
                        test
                        onChange={(_, value) => {
                          handleChange({
                            target: { name: 'sale_start', value },
                          });
                          errors[`${ticketType}.${index}.sale_start`] = '';
                        }}
                        value={ticket.sale_start}
                        error={errors.sale_start}
                        isResponsive={true}
                      />
                    </ResponsiveCol>
                    <ResponsiveCol>
                      <div
                        onClick={
                          ticket.sale_start === ''
                            ? () =>
                                toast.error(
                                  'Ticket sales start date is not selected'
                                )
                            : null
                        }
                        role="presentation"
                      >
                        <DatePicker
                          id="sale_end"
                          name="sale_end"
                          label="Ticket Sales End"
                          type="date"
                          onChange={(_, value) => {
                            handleChange({
                              target: { name: 'sale_end', value },
                            });
                            errors[`${ticketType}.${index}.sale_end`] = '';
                          }}
                          value={ticket.sale_end}
                          error={errors.sale_end}
                          disabled={ticket.sale_end === '' ? true : false}
                          isResponsive={true}
                        />
                      </div>
                    </ResponsiveCol>
                  </ResponsiveRow>
                  <ResponsiveRow style={{ marginTop: 30 }}>
                    <ResponsiveCol>
                      <DatePicker
                        id={'on_sale_time'}
                        type="time"
                        label={`Ticket on Sale Time`}
                        name="on_sale_time"
                        value={ticket.on_sale_time}
                        onChange={(name, event) => {
                          handleChange({
                            target: {
                              name,
                              value: event.target.value,
                            },
                          });
                        }}
                        isResponsive={true}
                      />
                    </ResponsiveCol>
                    <ResponsiveCol>
                      <DatePicker
                        id={'off_sale_time'}
                        type="time"
                        label={`Ticket Off Sale Time`}
                        name="off_sale_time"
                        value={ticket.off_sale_time}
                        onChange={(name, event) => {
                          handleChange({
                            target: {
                              name,
                              value: event.target.value,
                            },
                          });
                        }}
                        isResponsive={true}
                      />
                    </ResponsiveCol>
                  </ResponsiveRow>
                </>
              )}

              <Spacer size={10} />
              <ResponsiveRow>
                <ResponsiveCol>
                  <Checkbox
                    name="access_code"
                    checked={access_code}
                    onChange={(event) => {
                      const value = event.target?.checked;

                      setAccessCode(value);

                      handleChange({
                        target: {
                          name: `access_code`,
                          value: value,
                        },
                      });
                    }}
                    error={errors.access_code && errors.access_code}
                    rightText="Set Access Code"
                  />
                </ResponsiveCol>
              </ResponsiveRow>

              {access_code && (
                <>
                  <Spacer size={10} />
                  <ResponsiveRow>
                    <ResponsiveCol>
                      <Input
                        id={`${ticketType}.${index}.code`}
                        name={`${ticketType}.${index}.code`}
                        label="Access Code"
                        placeholder="Code"
                        onChange={({ target }) =>
                          handleChange({
                            target: {
                              name: `code`,
                              value: target.value,
                            },
                          })
                        }
                        value={ticket.code}
                        error={errors.code && errors.code}
                      />
                    </ResponsiveCol>
                    <ResponsiveCol></ResponsiveCol>
                  </ResponsiveRow>
                  <ResponsiveRow style={{ marginTop: 30 }}>
                    <ResponsiveCol>
                      <DatePicker
                        id="code_date"
                        name="code_date"
                        label="End Date"
                        type="date"
                        onChange={(_, value) => {
                          handleChange({
                            target: { name: 'code_date', value },
                          });
                          errors[`${ticketType}.${index}.code_date`] = '';
                        }}
                        value={ticket.code_date}
                        error={errors.code_date}
                        disabled={ticket.code_date === '' ? true : false}
                        isResponsive={true}
                      />
                    </ResponsiveCol>
                    <ResponsiveCol>
                      <DatePicker
                        id={'code_time'}
                        type="time"
                        label={`End Time`}
                        name="code_time"
                        value={ticket.code_time}
                        onChange={(name, event) => {
                          handleChange({
                            target: {
                              name,
                              value: event.target.value,
                            },
                          });
                        }}
                        isResponsive={true}
                      />
                    </ResponsiveCol>
                  </ResponsiveRow>
                </>
              )}

              <ResponsiveRow>
                <ResponsiveCol>
                  {reservedSeat && (
                    <InputWrapper>
                      <Input
                        style={{ marginRight: '15px', marginLeft: '40px' }}
                        id={`${ticketType}.${index}.reserved_seat_category`}
                        name={`${ticketType}.${index}.reserved_seat_category`}
                        label="Seats.io Category"
                        type="text"
                        placeholder=""
                        onChange={({ target }) =>
                          handleChange({
                            target: {
                              name: `reserved_seat_category`,
                              value: target.value,
                            },
                          })
                        }
                        value={ticket.reserved_seat_category ?? ''}
                      />
                    </InputWrapper>
                  )}
                </ResponsiveCol>
                <Col></Col>
              </ResponsiveRow>
            </>
          )}
        </ResponsiveCol>

        <ResponsiveCol>
          <InputWrapper>
            <Input
              style={{ marginRight: '15px' }}
              id={`${ticketType}.${index}.track_price`}
              name={`${ticketType}.${index}.track_price`}
              label="At Track Price"
              type="number"
              placeholder=""
              onChange={({ target }) =>
                handleChange({
                  target: {
                    name: `track_price`,
                    value: parseFloat(target.value),
                  },
                })
              }
              value={ticket.track_price ?? ''}
            />
          </InputWrapper>
          <Select
            id={`${ticketType}.${index}.color_code`}
            name={`${ticketType}.${index}.color_code`}
            error={
              errors[`${ticketType}.${index}.color_code`]
                ? errors[`${ticketType}.${index}.color_code`]
                : ''
            }
            label="Color Code"
            placeholder="Select a Color"
            options={COLOR_CODES}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `color_code`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.color_code`] = '';
            }}
            value={ticket.color_code}
            backgroundColor={ticket.color_code}
            changeTextColor={true}
          />
          <Select
            id={`${ticketType}.${index}.category_id`}
            name={`${ticketType}.${index}.category_id`}
            error={
              errors[`${ticketType}.${index}.category_id`]
                ? errors[`${ticketType}.${index}.category_id`]
                : ''
            }
            label="Ticket Category"
            placeholder="Category"
            options={allCategories}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `category_id`,
                  value: +target.value,
                },
              });
              errors[`${ticketType}.${index}.category_id`] = '';
            }}
            value={ticket.category_id}
          />
          <Select
            id={`${ticketType}.${index}.visibility`}
            name={`${ticketType}.${index}.visibility`}
            error={
              errors[`${ticketType}.${index}.visibility`]
                ? errors[`${ticketType}.${index}.visibility`]
                : ''
            }
            label="Visibility"
            placeholder="Visibility"
            options={VisibilityOptions}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `visibility`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.visibility`] = '';
            }}
            value={ticket.visibility ?? 'visible'}
          />
        </ResponsiveCol>
      </ResponsiveRow>
      {/* </div> */}
    </>
  );
};
