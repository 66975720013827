import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { PrintButton } from 'components/PrintButton';
import { KartPassLogo } from 'pages/app/Transactions/TransactionsPrintList/kartpass-logo';
import { Logo } from 'pages/app/Transactions/TransactionsPrintList/logo';
import { TicketHossLogo } from 'pages/app/Transactions/TransactionsPrintList/tickethoss-logo';
import { timeout } from 'shared/timeout';
import { useGetEventPerformanceReport } from '../gql/mutation/useGetEventPerformanceReport.mutation';

const getHeaders = () => {
  return [
    [
      'Date/Time',
      'Purchaser',
      'Order Total',
      'Discount',
      'Refund',
      'Credit',
      'Absorbed Fees',
      'Transaction Total',
    ],
  ];
};

const getColumns = () => {
  return [
    { dataKey: 'date', header: 'Date/Time' },
    { dataKey: 'purchaser', header: 'Purchaser' },
    { dataKey: 'subtotal', header: 'Order Total' },
    { dataKey: 'discount', header: 'Discount' },
    { dataKey: 'refund', header: 'Refund' },
    { dataKey: 'credit', header: 'Credit' },
    { dataKey: 'fee', header: 'Absorbed Fees' },
    { dataKey: 'total', header: 'Transaction Total' },
  ];
};

const buildData = (transactions) => {
  return (
    transactions?.length > 0 &&
    transactions.map((transaction) => [
      transaction.purchase_date,
      transaction.purchaser,
      transaction.subtotal,
      transaction.discount,
      transaction.refund ? `${transaction.refund}` : '',
      transaction.credit,
      transaction.fee,
      transaction.total,
    ])
  );
};

const getLapColumns = () => {
  return [
    { dataKey: 'name', header: '' },
    { dataKey: 'price', header: '' },
    { dataKey: 'download', header: '' },
  ];
};

const buildLapData = (sponsorships) => {
  return (
    sponsorships?.length > 0 &&
    sponsorships.map((sponsorship) => [
      sponsorship.name,
      sponsorship.total,
      'Click to Download Detailed Report',
    ])
  );
};

const getEventSalesColumns = () => {
  return [
    { dataKey: 'name', header: '' },
    { dataKey: 'amount', header: '' },
  ];
};

const buildEventSalesData = (sales) => {
  return sales?.length > 0 && sales.map((sale) => [sale.label, sale.amount]);
};

const getEventPayout = (sales) => {
  const payout = sales.find((sale) => 'Event Payout' === sale.label);
  return payout?.amount;
};

const getBreakdownColumns = () => {
  return [
    { dataKey: 'name', header: 'Ticket Breakdown' },
    { dataKey: 'price', header: 'Price' },
    { dataKey: 'quantity', header: 'Quantity' },
  ];
};

const buildgBreakdownData = (breakdown) => {
  return (
    breakdown?.length > 0 &&
    breakdown.map((data) => [data.name, data.price, data.qty])
  );
};

export const PrintEventPerformanceReport = ({
  text,
  color,
  eventId,
  button,
}) => {
  const isKartPass = 'kartpass' === process.env.REACT_APP_PLATFORM;
  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const getEventPerformanceReport = useGetEventPerformanceReport(eventId);
  const [disabled, setDisabled] = useState(false);

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : pathname?.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';
  const isStaging = window.location.href.includes('dashboard-staging');

  let filename = '';

  const getData = async () => {
    try {
      const response = await getEventPerformanceReport(eventId);
      let title = '';
      const eventPerformanceReport = response.data.getEventPerformanceReport;

      const { event } = eventPerformanceReport;

      if (event.isMultiDay)
        title = `${moment(event.start_date)
          .format('MMM DD')
          .toUpperCase()} - ${moment(event.end_date)
          .format('MMM DD - YYYY')
          .toUpperCase()}`;
      else
        title = moment(event.start_date).format('MMM DD - YYYY').toUpperCase();

      // Add Platform to the filename with the first letter capitalized
      const platform = process.env.REACT_APP_PLATFORM;
      const platformName = `${platform.charAt(0).toUpperCase()}${platform.slice(
        1
      )}`;

      filename = platformName + ' - Event Performance Report - ' + title;
      return { eventPerformanceReport, title, filename };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      toast.error('Error generating report');
      setDisabled(false);
    }
  };

  function generatePdf() {
    setDisabled(true);
    return new Promise(async (resolve) => {
      const pdf = await exportPDF();
      if (pdf.fileName || filename) {
        pdf.setProperties({
          title: pdf.fileName ?? filename,
        });
      }
      if (
        navigator.userAgent.includes('iPhone') &&
        navigator.maxTouchPoints > 1
      ) {
        var blob = new Blob([pdf.output('blob')], {
          type: 'data:application/pdf,',
        });
        FileSaver.saveAs(blob, pdf.fileName ?? filename + '.pdf');
      } else if (navigator.maxTouchPoints > 1) {
        pdf.output('datauri', pdf.fileName ?? filename + '.pdf');
      } else {
        // Generate the PDF Blob
        const blob = pdf.output('blob');

        // Create a Blob URL for the PDF
        const blobUrl = URL.createObjectURL(blob);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = pdf.fileName ?? filename + '.pdf'; // Set the desired filename

        // Trigger a click event on the download link
        downloadLink.click();
      }
      timeout(resolve, 6000);
    });
  }

  //This will generate the spectator PDF report
  const exportPDF = async () => {
    const { eventPerformanceReport, filename, title } = await getData();

    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    let Y = 40;

    const extra = 30;

    doc.text(title, Y, Y);
    Y += 20;

    if (eventPerformanceReport?.label) {
      doc.text(eventPerformanceReport?.label ?? '', 40, Y);
      Y += 20;
    }
    if (eventPerformanceReport?.event?.name) {
      doc.text(eventPerformanceReport?.event?.name, 40, Y);
      Y += 20;
    }
    if (eventPerformanceReport?.event?.track?.name) {
      doc.text(eventPerformanceReport?.event?.track?.name, 40, Y);
      Y += 20;
    }
    if (eventPerformanceReport.marketing_fund) {
      doc.setTextColor('#fa4616');
      doc.text(`1% Cash Back ${eventPerformanceReport.marketing_fund}`, 40, Y);
      doc.setTextColor('#000');
      Y += 20;
    }
    doc.text(
      `Event Payout ${getEventPayout(eventPerformanceReport?.sales_data)}`,
      40,
      Y
    );

    if (eventPerformanceReport.event.hide_fee) {
      Y += 20;
      doc.setTextColor('#fa4616');
      doc.text(`Promoter Absorbed Fees`, 40, Y);
      doc.setTextColor('#000');
    }

    doc.text(`Event Performance Report`, 630, Y);

    Y += 60;

    if (isKartPass) {
      doc.addImage(KartPassLogo, 'PNG', 650, 40, 100, 75, '', 'FAST');
    } else if (isTicketHoss) {
      doc.addImage(TicketHossLogo, 'PNG', 660, 20, 100, 54, '', 'FAST');
    } else {
      doc.addImage(Logo, 'PNG', 660, 40, 100, 100, '', 'FAST');
    }

    const headers = getHeaders();

    // Event Sales detail table
    const salesData = buildEventSalesData(eventPerformanceReport?.sales_data);

    doc.text(`Event Breakdown`, 40, Y);
    Y += 10;

    doc.autoTable({
      startY: Y,
      rowPageBreak: 'avoid',
      columns: getEventSalesColumns(),
      body: salesData,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: 'white',
      },
      tableWidth: 'auto',
      willDrawCell: function (data) {
        // Check if the current row is the last row
        if (
          data.section === 'body' &&
          data.row.index === salesData.length - 1
        ) {
          // Apply bold style to the entire row
          doc.setFontStyle('bold');
          doc.setTextColor(0, 0, 0);
        }
      },
    });
    // End of Event Sales detail table

    let availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    // Lap Sponsorships table
    const lapData = buildLapData(eventPerformanceReport?.lap_sponsorships_data);

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Lap Sponsorships: ${eventPerformanceReport?.lap_sponsorships_total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getLapColumns(),
      body: lapData,
      styles: {
        fontStyle: 'bold',
      },
      columnStyles: {
        download: {
          textColor: '#fa4616',
        },
      },
      headStyles: {
        fillColor: 'white',
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 2) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/events/reports/lapSponsorshipReport/${
            eventPerformanceReport.lap_sponsorships_data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });
    // End of Lap Sponsorships table

    // Transactions table
    const breakdownData = buildgBreakdownData(
      eventPerformanceReport?.ticket_breakdown_data
    );

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getBreakdownColumns(),
      head: [['Ticket Breakdown', 'Price', 'Quantity']],
      body: breakdownData,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      tableWidth: 'auto',
    });
    // End of Transactions table

    // Credit Redeemed table
    const data1 = buildData(eventPerformanceReport?.data[1]?.data);

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Credits Redeemed - ${eventPerformanceReport?.data[1]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data1,
      styles: {
        fontStyle: 'bold',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/detail/${
            eventPerformanceReport.data[1].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });
    // End of Credit Redeemed table

    // Discount table
    const data3 = buildData(eventPerformanceReport?.data[3]?.data);

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Discounts - ${eventPerformanceReport?.data[3]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data3,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/detail/${
            eventPerformanceReport.data[3].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });
    // End of Discount table

    // Refunds Issued table
    const data4 = buildData(eventPerformanceReport?.data[4]?.data);

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Refunds Issued - ${eventPerformanceReport?.data[4]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(),
      head: headers,
      body: data4,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/detail/${
            eventPerformanceReport.data[4].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });
    // End of Refunds Issued table

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      const now = moment();
      const momentInSpecificTimezone = now.tz('America/New_York');
      const dateTimeStamp =
        momentInSpecificTimezone.format('MMM DD - YYYY h:mm A').toUpperCase() +
        ' EST';
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: `${filename}.pdf`,
      subject: 'Event Performance Report',
      author: process.env.REACT_APP_PLATFORM,
    });

    return {
      ...doc,
      fileName: `${filename}.pdf`,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <PrintButton
        buildPdf={generatePdf}
        text={text}
        variant="minimal"
        disabled={disabled}
        color={color}
        button={button}
      />
    </div>
  );
};
