import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import { formatPhoneNumber } from 'shared/formatters';
import Export from './exportCustomers';
import { useGetCustomers } from './gql/queries/get-customers';

const Users = ({ theme }) => {
  const { data } = useGetCustomers();
  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const columns = [
    {
      label: 'User Name',
      key: 'name',
    },
    {
      label: 'Mobile Phone',
      key: 'cellphone',
    },
    {
      label: 'User Email',
      key: 'email',
    },
    {
      label: 'Zip Code / Postal Code',
      key: 'zipcode',
    },
  ];

  function renderRows(user) {
    const { id, formatted_name, email, cellphone, zipcode } = user;
    return {
      name: (
        <Link
          to={`/admin-${userType}/users/transactions/${id}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText>{`${formatted_name}`}</LineHeightText>
        </Link>
      ),
      email,
      cellphone: formatPhoneNumber(cellphone),
      zipcode,
    };
  }

  return !data?.getCustomersV2 ? (
    <div />
  ) : (
    <>
      <Export />
      <SearchableListContainer
        pageCount={data.getCustomersV2.count}
        paginated
        title="Customers"
        searchInputPlaceholder="Mobile Lookup"
      >
        <Table
          items={data.getCustomersV2.results}
          columns={columns}
          renderRows={renderRows}
        />
      </SearchableListContainer>
    </>
  );
};

export default withTheme(Users);
