/* eslint-disable */
import { omit } from 'lodash';
import moment from 'moment';

import {
  TabContent,
  Tabs,
  AddUpdateBtn,
  Tab,
  Content,
  Header,
  EventBody,
  SectionTitle,
  ResponsiveRow,
  ResponsiveCol,
} from 'pages/app/Events/AddEvents/styles';
import EventHeader from 'components/Events/EventHeader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { useGetEvent } from 'components/Events/gql';
import { FaqList } from 'components/Faqs';
import { NoteList } from 'components/Notes';
import { useGetMatrices } from 'components/FeeMatrices/gql';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import { DatePicker } from 'components/Form/DatePicker';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import { Label, ErrorText } from 'components/Form/styles';
import RowSection from 'components/RowSection';
import { SeriesSelect } from 'components/SeriesSelect';
import Spacer from 'components/Spacer';
import DateTimeRepeater from 'pages/app/Events/EventEdit/DateTimeRepeater';
import { EditEventTickets as Tickets } from 'pages/app/Events/EventEdit/EditEventTickets';
import Tracks from 'pages/app/Events/EventEdit/tracks';
import { WaiversRepeater } from 'pages/app/Events/EventEdit/WaiversRepeater';
import Account from '../EventEdit/Dropdown/account';
import { LapSponsorshipEvent } from '../LapSponsorship/LapSponsorshipEvent';
import CreateEvent from 'components/Events/gql/CreateEvent.mutation';
import UpdateEvent from 'components/Events/gql/UpdateEvent.mutation';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { GeneralInfo } from './components/GeneralInfo';
import { PointOfSaleSettings } from 'pages/app/Accounts/AddAccounts/components/PointOfSaleSettings';
import { getTimes, setTime } from 'shared/timeUtils';

const formValues = [
  'name',
  'start_date',
  // 'added_by',
  'end_date',
  'sale_start',
  'sale_end',
  'off_sale_time',
  'on_sale_time',
  'status',
  'status_color',
  'admin_tickets',
  'admin_ticket_view',
  'admin_ticket_view_key',
  'admin_other_tickets',
  'admin_other_view',
  'admin_bundle_tickets',
  'admin_multiday_tickets',
  'image_id',
  'image',
  'logo_id',
  'logo',
  'track_id',
  'series_ids',
  'user_id',
  'account_id',
  'faqs',
  'admin_website_url',
  'admin_schedule_url',
  'capacity',
  // 'event_capacity',
  'show_pitpay',
  'show_tickethoss',
  'individual_tickets',
  'facebook',
  'instagram',
  'twitter',
  'waivers',
  'about',
  'admin_times',
  'schedule_details',
  'hide_fee',
  'international_fee',
  'registration_sale_start',
  'registration_sale_end',
  'registration_off_time',
  'registration_email',
  'other_fees',
  'registration_fees',
  'matrix_id',
  'marketing_fee',
  'lap_sponsorships',
  'promoter_name',
  'promoter_email',
  'promoter_phone',
  'promoter_organization',
  'promoter_website',
  'infos',
  'infos_with_order',
  'url',
  'info_video_url',
  'promo_image_id',
  'promo_image',
  'schedule_detail_image_id',
  'schedule_detail_image',
  'schedule_detail_url',

  'facebook_image_id',
  'facebook_image',

  'twitter_image_id',
  'twitter_image',
  'seats_id',
  'track',
  'series',
  'pos',
  'pos_settings',

  'code',
  'code_time',
  'code_date',
];

export const DESCRIPTION_CHARACTER_LIMIT = 200;

const descriptionError = `Ticket Descriptions must be less than ${DESCRIPTION_CHARACTER_LIMIT} characters long`;

function sortByOrderField(a, b) {
  // We want faqs with no `order` to be last, not first
  return (a.order ?? Infinity) - (b.order ?? Infinity);
}

function getInitialValues(
  event,
  admin_times_modified,
  off_sale_time_modified,
  on_sale_time_modified,
  registration_off_time_modified,
  keys
) {
  let {
    user,
    admin_times,
    end_date,
    admin_tickets,
    admin_other_tickets,
    admin_multiday_tickets,
    admin_bundle_tickets,
    faqs,
    off_sale_time,
    on_sale_time,
    registration_off_time,
    series,
    waivers,
    other_fees,
    registration_fees,
    matrix,
    lap_sponsorships,
    notes,
    admin_infos,
    track,
    code_time,
    code,
  } = event ?? {};
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
    user_id: {
      value: '',
      label: '',
    },
    admin_times: [],
    admin_tickets: [],
    admin_ticket_view: [],
    admin_ticket_view_key: [],
    admin_bundle_tickets: [],
    admin_multiday_tickets: [],
    admin_other_tickets: [],
    admin_other_view: [],
    faqs: [],
    off_sale_time: null,
    on_sale_time: null,
    registration_off_time: null,
    series_ids: [],
    waivers: [],
    other_fees: [],
    registration_fees: [],
    marketing_fee: true,
    lap_sponsorships: [],
    notes: [],
    promoter_name: '',
    promoter_email: '',
    promoter_phone: '',
    promoter_organization: '',
    promoter_website: '',
    international_fee: true,
    infos: [],
    infos_with_order: [],
    individual_tickets: true,
  };

  //set the types for the combined view
  if (admin_tickets) {
    admin_tickets = admin_tickets.map((ticket) => ({
      ...ticket,
      type: 'admin',
    }));
  } else if (admin_multiday_tickets) {
    admin_multiday_tickets = admin_multiday_tickets.map((ticket) => ({
      ...ticket,
      type: 'admin',
    }));
  } else if (admin_other_tickets) {
    admin_other_tickets = admin_other_tickets.map((ticket) => ({
      ...ticket,
      type: 'other',
    }));
  } else if (
    admin_bundle_tickets &&
    process.env.REACT_APP_PLATFORM === 'tickethoss'
  ) {
    admin_bundle_tickets = admin_bundle_tickets.map((ticket) => ({
      ...ticket,
      type: 'bundle',
    }));
  }

  let combined_tickets = [];
  let combined_tickets_map = {};
  if (event && Array.isArray(admin_tickets)) {
    combined_tickets = admin_tickets
      .concat(admin_multiday_tickets)
      .concat(admin_other_tickets)
      .concat(admin_bundle_tickets)
      .sort(sortByOrderField)
      .map((ticket) => ({
        ...ticket,
        price: ticket.price.toFixed(2),
      }));

    combined_tickets_map = combined_tickets.reduce((acc, ticket) => {
      if (ticket.category_id) {
        return {
          ...acc,
          [ticket.category_id]: acc[ticket.category_id]
            ? acc[ticket.category_id].concat(ticket)
            : [ticket],
        };
      } else {
        return {
          ...acc,
          Unassigned: acc['Unassigned']
            ? acc['Unassigned'].concat(ticket)
            : [ticket],
        };
      }
    }, {});
  }

  const initialValues = !event
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return event[key]
            ? {
                ...formValues,
                [key]: event[key],
              }
            : formValues;
        }, {}),
        user_id: user
          ? {
              value: user.id,
              label: `${user.first_name} ${
                user.middle_name ? `${user.middle_name} ` : ''
              }${user.last_name}`,
            }
          : '',
        admin_times:
          admin_times && admin_times.length
            ? admin_times_modified
            : [
                {
                  event_date: end_date
                    ? moment(new Date(end_date), 'MM-DD-YYYY').format(
                        'MM-DD-YYYY'
                      )
                    : '',
                },
              ],

        ...(Array.isArray(admin_tickets)
          ? {
              admin_tickets: admin_tickets
                .sort(sortByOrderField)
                .map((ticket) => ({
                  ...ticket,
                  price: ticket.price.toFixed(2),
                  on_sale_time:
                    ticket.on_sale_time &&
                    ticket.on_sale_time !== 'Invalid date'
                      ? setTime(ticket.on_sale_time)
                      : null,
                  off_sale_time:
                    ticket.off_sale_time &&
                    ticket.off_sale_time !== 'Invalid date'
                      ? setTime(ticket.off_sale_time)
                      : null,
                  code_time:
                    ticket.code_time && ticket.code_time !== 'Invalid date'
                      ? setTime(ticket.code_time)
                      : null,
                })),
            }
          : undefined),

        ...(Array.isArray(admin_tickets) && {
          admin_ticket_view: combined_tickets,
        }),
        ...(Array.isArray(admin_tickets) && {
          ...combined_tickets_map,
        }),
        ...(Array.isArray(admin_tickets) && {
          admin_ticket_view_key: Object.keys(combined_tickets_map),
        }),
        ...(Array.isArray(admin_tickets) &&
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? {
              admin_ticket_view: admin_tickets
                .concat(admin_multiday_tickets)
                .sort(sortByOrderField)
                .map((ticket) => ({
                  ...ticket,
                  price: ticket.price.toFixed(2),
                  on_sale_time:
                    ticket.on_sale_time &&
                    ticket.on_sale_time !== 'Invalid date'
                      ? setTime(ticket.on_sale_time)
                      : null,
                  off_sale_time:
                    ticket.off_sale_time &&
                    ticket.off_sale_time !== 'Invalid date'
                      ? setTime(ticket.off_sale_time)
                      : null,
                  code_time:
                    ticket.code_time && ticket.code_time !== 'Invalid date'
                      ? setTime(ticket.code_time)
                      : null,
                })),
            }
          : undefined),

        ...(Array.isArray(admin_multiday_tickets)
          ? {
              admin_multiday_tickets: admin_multiday_tickets
                .sort(sortByOrderField)
                .map((ticket) => ({
                  ...ticket,
                  price: ticket.price.toFixed(2),
                  on_sale_time:
                    ticket.on_sale_time &&
                    ticket.on_sale_time !== 'Invalid date'
                      ? setTime(ticket.on_sale_time)
                      : null,
                  off_sale_time:
                    ticket.off_sale_time &&
                    ticket.off_sale_time !== 'Invalid date'
                      ? setTime(ticket.off_sale_time)
                      : null,
                  code_time:
                    ticket.code_time && ticket.code_time !== 'Invalid date'
                      ? setTime(ticket.code_time)
                      : null,
                })),
            }
          : undefined),
        ...(Array.isArray(admin_other_tickets)
          ? {
              admin_other_tickets: admin_other_tickets
                .sort(sortByOrderField)
                .map((ticket) => ({
                  ...ticket,
                  price: ticket.price.toFixed(2),
                  on_sale_time:
                    ticket.on_sale_time &&
                    ticket.on_sale_time !== 'Invalid date'
                      ? setTime(ticket.on_sale_time)
                      : null,
                  off_sale_time:
                    ticket.off_sale_time &&
                    ticket.off_sale_time !== 'Invalid date'
                      ? setTime(ticket.off_sale_time)
                      : null,
                  code_time:
                    ticket.code_time && ticket.code_time !== 'Invalid date'
                      ? setTime(ticket.code_time)
                      : null,
                })),
            }
          : undefined),
        ...(Array.isArray(admin_other_tickets)
          ? {
              admin_other_view: admin_other_tickets
                .sort(sortByOrderField)
                .map((ticket) => ({
                  ...ticket,
                  price: ticket.price.toFixed(2),
                  on_sale_time:
                    ticket.on_sale_time &&
                    ticket.on_sale_time !== 'Invalid date'
                      ? setTime(ticket.on_sale_time)
                      : null,
                  off_sale_time:
                    ticket.off_sale_time &&
                    ticket.off_sale_time !== 'Invalid date'
                      ? setTime(ticket.off_sale_time)
                      : null,
                  code_time:
                    ticket.code_time && ticket.code_time !== 'Invalid date'
                      ? setTime(ticket.code_time)
                      : null,
                })),
            }
          : undefined),
        ...(Array.isArray(admin_bundle_tickets)
          ? {
              admin_bundle_tickets: admin_bundle_tickets.map((ticket) => ({
                ...ticket,
                price: ticket.price.toFixed(2),
                associated_tickets: Array.isArray(ticket.associated_tickets)
                  ? ticket.associated_tickets
                  : [],
              })),
            }
          : undefined),
        ...(faqs
          ? {
              faqs: faqs
                .sort(sortByOrderField)
                .map((faq) => omit(faq, ['__typename'])),
            }
          : []),
        ...(off_sale_time ? { off_sale_time: off_sale_time_modified } : null),
        ...(on_sale_time ? { on_sale_time: on_sale_time_modified } : null),
        ...(registration_off_time
          ? { registration_off_time: registration_off_time_modified }
          : null),
        series_ids: Array.isArray(series)
          ? series.map((series) => ({
              value: series.id,
              label: series.name,
            }))
          : [],
        ...(Array.isArray(waivers)
          ? {
              waivers: waivers
                .filter(Boolean)
                .reduce(
                  (allWaivers, waiver) => [
                    ...allWaivers,
                    { waiver: waiver.id },
                  ],
                  []
                ),
            }
          : []),
        other_fees: other_fees
          ? other_fees.map((fee) => {
              // eslint-disable-next-line no-unused-vars
              const { __typename, ...rest } = fee;
              return rest;
            })
          : [],
        registration_fees: registration_fees
          ? registration_fees.map((fee) => {
              // eslint-disable-next-line no-unused-vars
              const { __typename, ...rest } = fee;
              return rest;
            })
          : [],
        matrix_id: matrix
          ? {
              value: matrix.id,
              label: matrix.name,
            }
          : null,
        lap_sponsorships: lap_sponsorships ? lap_sponsorships : [],
        lap_sponsorships_with_order: lap_sponsorships ?? [],
        notes: notes ? notes : [],
        infos: admin_infos
          ? admin_infos.map((info) => {
              return {
                ...info,
                info: { value: info.info.id, label: info.info.name },
              };
            })
          : [],
        infos_with_order: admin_infos
          ? admin_infos.map((info) => {
              return {
                ...info,
                info: { value: info.info.id, label: info.info.name },
              };
            })
          : [],
        track: track ? track : null,
        series: series ? series : null,
        code_time:
          code_time && code_time !== 'Invalid date' ? setTime(code_time) : null,
        access_code: !!code,
      };
  return initialValues;
}

function getTicketWithOrders(ticket, index) {
  const {
    id,
    name,
    start_date,
    end_date,
    color_code,
    type,
    price,
    description,
    isMultiDay,
    associated_tickets,
    special_ticket,
    fee_dollar,
    fee_percent,
    pos_fee_dollar,
    pos_fee_percent,
    is_featured,
    featured_text,
    track_price,
    limit,
    low_ticket_alert,
    form_id,
    reserved_seat,
    category_id,
    sale_start,
    sale_end,
    on_sale_time,
    off_sale_time,
    reserved_seat_category,
    ticket_tiers,
    visibility,
    access_code,
    code,
    code_date,
    code_time,
    reserved_seat_prefix,
    reserved_seat_section,
  } = ticket;
  return {
    id,
    name,
    start_date,
    end_date,
    color_code,
    type,
    description,
    limit: limit ? parseInt(limit) : null,
    price: price ? parseFloat(price) : 0,
    order: index,
    isMultiDay,
    associated_tickets,
    fee_dollar: special_ticket ? fee_dollar : null,
    fee_percent: special_ticket ? fee_percent : null,
    pos_fee_dollar: pos_fee_dollar ? pos_fee_dollar : null,
    pos_fee_percent: pos_fee_percent ? pos_fee_percent : null,
    is_featured: is_featured ?? false,
    featured_text: featured_text ?? null,
    track_price: track_price ?? null,
    limit: limit ?? null,
    low_ticket_alert: low_ticket_alert ?? null,
    ...(form_id ? { form_id } : null),
    reserved_seat: reserved_seat ?? false,
    category_id,
    sale_start: sale_start ?? null,
    sale_end: sale_end ?? null,
    on_sale_time: on_sale_time ? on_sale_time : null,
    off_sale_time: off_sale_time ? off_sale_time : null,
    reserved_seat_category: reserved_seat_category ?? null,
    ticket_tiers: ticket_tiers && ticket_tiers.length > 0 ? ticket_tiers : null,
    visibility: visibility ?? 'visible',
    code: access_code && code ? code : null,
    code_date: access_code && code_date ? code_date : null,
    code_time: access_code && code_time ? code_time : null,
    reserved_seat_prefix: reserved_seat_prefix,
    reserved_seat_section: reserved_seat_section,
  };
}

function getLapsWithOrders(sponsorship, index) {
  const { id, name, num_laps, price, payout_1, payout_2, payout_3 } =
    sponsorship;
  return {
    id,
    name,
    num_laps,
    price: price ? parseFloat(price) : 0,
    payout_1,
    payout_2,
    payout_3,
    order: index,
  };
}

function getInfosWithOrders(infos, index) {
  const { id, info, link, text, logo_id, video_url, image_id, pdf_id, type } =
    infos;
  return {
    id,
    info,
    link,
    text,
    logo_id,
    order: index,
    video_url,
    image_id,
    pdf_id,
    type,
  };
}

function getTicketValues(ticket, index) {
  const {
    id,
    name,
    start_date,
    end_date,
    color_code,
    type,
    price,
    description,
    order,
    fee_dollar,
    fee_percent,
    pos_fee_dollar,
    pos_fee_percent,
    is_featured,
    featured_text,
    track_price,
    limit,
    reserved_seat,
    category_id,
    sale_start,
    sale_end,
    on_sale_time,
    off_sale_time,
    reserved_seat_category,
    ticket_tiers,
    visibility,
    code,
    code_date,
    code_time,
    reserved_seat_prefix,
    reserved_seat_section,
  } = ticket;
  return {
    id,
    name,
    start_date,
    end_date,
    color_code,
    type,
    description,
    limit: limit ? parseInt(limit) : null,
    price: price ? parseFloat(price) : 0,
    order: order,
    fee_dollar: fee_dollar ?? null,
    fee_percent: fee_percent ?? null,
    pos_fee_dollar: pos_fee_dollar ?? null,
    pos_fee_percent: pos_fee_percent ?? null,
    is_featured: is_featured ?? false,
    featured_text: featured_text ?? null,
    track_price: track_price ?? null,
    limit: limit ?? null,
    reserved_seat: reserved_seat ?? false,
    category_id,
    sale_start: sale_start ?? null,
    sale_end: sale_end ?? null,
    on_sale_time:
      on_sale_time && typeof on_sale_time !== 'string'
        ? getTimes(on_sale_time)
        : on_sale_time,
    off_sale_time:
      off_sale_time && typeof off_sale_time !== 'string'
        ? getTimes(off_sale_time)
        : off_sale_time,
    reserved_seat_category: reserved_seat_category ?? null,
    ticket_tiers: ticket_tiers && ticket_tiers.length > 0 ? ticket_tiers : null,
    visibility: visibility ?? 'visible',
    code: code ?? null,
    code_date: code_date ?? null,
    code_time:
      code_time && typeof code_time !== 'string'
        ? getTimes(code_time)
        : code_time,
    reserved_seat_prefix: reserved_seat_prefix ?? null,
    reserved_seat_section: reserved_seat_section ?? null,
  };
}

function getBundleTicketValues(ticket, index) {
  const {
    id,
    name,
    color_code,
    type,
    limit,
    price,
    description,
    associated_tickets,
    order,
    category_id,
  } = ticket;
  return {
    id,
    name,
    color_code,
    type,
    description,
    limit: limit ? parseInt(limit) : null,
    price: price ? parseFloat(price) : 0,
    order: order,
    associated_tickets,
    category_id,
  };
}

function getFeesInput(fees, action) {
  return fees
    .filter(
      (fee) =>
        !Object.entries(fee).reduce((allBlank, [key, value]) =>
          key === 'id' ? allBlank : value ? false : allBlank
        ),
      true
    )
    .map((fee, index) => ({
      ...Object.entries(fee).reduce(
        (properties, [key, value]) => ({
          ...properties,
          [key]: Number(value),
        }),
        {}
      ),
      ...(action !== 'update' ? { id: index } : null),
    }));
}

function getEventInput({
  data,
  currentTrack,
  currentEvent,
  currentSeries,
  action,
}) {
  const input = {
    ...data,
    ...(action === 'update' ? { id: parseInt(currentEvent) } : null),
    off_sale_time: data.off_sale_time ? getTimes(data.off_sale_time) : null,
    on_sale_time: data.on_sale_time ? getTimes(data.on_sale_time) : null,
    registration_off_time: data.registration_off_time
      ? getTimes(data.registration_off_time)
      : null,
    track_id: currentTrack
      ? parseInt(currentTrack, 10)
      : data.track_id
      ? data.track_id.value
      : null,
    account_id: data.account_id ? data.account_id.value : null,
    admin_times: data.admin_times
      ? data.admin_times.reduce((acc, time) => {
          const { id, event_date, gate_time, start_time } = time;
          if (gate_time || start_time)
            return [
              ...acc,
              {
                id,
                event_date,
                gate_time: gate_time ? getTimes(gate_time) : null,
                start_time: start_time ? getTimes(start_time) : null,
              },
            ];
        }, [])
      : null,
    admin_tickets: data.admin_tickets
      ? data.admin_tickets.map((ticket, index) => ({
          ...getTicketValues(ticket, index),
        }))
      : null,
    admin_multiday_tickets: data.admin_multiday_tickets
      ? data.admin_multiday_tickets.map((ticket, index) => ({
          ...getTicketValues(ticket, index),
        }))
      : null,
    admin_other_tickets: data.admin_other_tickets
      ? data.admin_other_tickets.map((ticket, index) => ({
          ...getTicketValues(ticket, index),
        }))
      : null,
    admin_bundle_tickets: data.admin_bundle_tickets
      ? data.admin_bundle_tickets.map((ticket, index) => ({
          ...getBundleTicketValues(ticket, index),
        }))
      : null,
    user_id: data.user_id ? data.user_id.value : null,
    waivers: data.waivers
      ? data.waivers
          .filter(Boolean)
          .map((waiver) => parseInt(waiver.waiver, 10))
      : null,
    series_ids: data.series_ids
      ? data.series_ids.map((item) => item.value)
      : parseInt(currentSeries, 10),
    faqs: data.faqs.map((faq, index) => {
      // Destructure out the `react-sortablejs` additions
      /* eslint-disable-next-line no-unused-vars */
      const { chosen, selected, filtered, ...rest } = faq;
      return {
        ...rest,
        order: index,
      };
    }),
    ...(data.other_fees
      ? {
          other_fees: getFeesInput(data.other_fees, action),
        }
      : null),
    ...(data.registration_fees
      ? {
          registration_fees: getFeesInput(data.registration_fees, action),
        }
      : null),
    ...(data.matrix_id?.value
      ? { matrix_id: data.matrix_id.value }
      : undefined),
    admin_infos: data.admin_infos
      ? data.admin_infos.map((info) => {
          return {
            ...(info.id ? { id: info.id } : null),
            logo_id: info.logo_id ? Number(info.logo_id) : null,
            link: info.link,
            text: info.text,
            info_id: info.info.value,
            order: info.order,
            video_url: info.video_url,
            image_id: info.image_id ? Number(info.image_id) : null,
            pdf_id: info.pdf_id ? Number(info.pdf_id) : null,
            type: info.type ? info.type : 'event',
          };
        })
      : null,
    twitter_image_id: data.twitter_image_id ? data.twitter_image_id : null,
    facebook_image_id: data.facebook_image_id ? data.facebook_image_id : null,
    promo_image_id: data.promo_image_id ? data.promo_image_id : null,
    schedule_detail_image_id: data.schedule_detail_image_id
      ? data.schedule_detail_image_id
      : null,
    url: data.url ? data.url : null,
    info_video_url: data.info_video_url ? data.info_video_url : null,
    individual_tickets: false, //Pass false value on create and edit always
    code_time: data.code_time ? getTimes(data.code_time) : null,
  };

  formValues.forEach((key) => {
    if (
      key !== 'twitter_image_id' &&
      key !== 'facebook_image_id' &&
      key !== 'promo_image_id' &&
      key !== 'url' &&
      key !== 'info_video_url' &&
      key !== 'image_url' &&
      key !== 'pdf_url' &&
      key !== 'schedule_detail_image_id' &&
      key !== 'schedule_details' &&
      key !== 'schedule_detail_url' &&
      key !== 'code' &&
      key !== 'code_time' &&
      key !== 'code_date'
    )
      if (typeof input[key] != 'boolean' && !input[key]) delete input[key];
  });

  return input;
}

function validateTicketDescriptionCharacterCounts(tickets, characterLimit) {
  if (!tickets.length) {
    return true;
  }

  const areAllValid = tickets.reduce((allValid, ticket) => {
    return typeof ticket.description === 'string' &&
      ticket.description.length > characterLimit
      ? false
      : allValid;
  }, true);
  return areAllValid;
}

const AddEvents = ({
  isVisible,
  handleOutClick,
  id,
  newEvent,
  match,
  createEvent,
  updateEvent,
  props,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const theme = useTheme();
  const [active, setActive] = useState(0);
  const [is_multiday, setMultiDay] = useState(null);

  const [admin_times_modified, set_admin_times_modified] = useState(null);
  const [off_sale_time_modified, set_off_sale_time_modified] = useState(null);
  const [on_sale_time_modified, set_on_sale_time_modified] = useState(null);
  const [registration_off_time_modified, set_registration_off_time_modified] =
    useState(null);

  const [trackTitle, setTrackTitle] = useState('test');

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const currentEvent = params.id;
  const currentTrack = params.currentTrack;
  const currentSeries = params.currentSeries;

  const successMessage = () =>
    toast.success(currentEvent ? 'Event Updated' : 'Event Created');
  const errorMessage = (response, message) => {
    toast.error(
      message
        ? message
        : currentEvent
        ? response.global
          ? 'Error Updating Event'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Event'
    );
  };

  const { data: eventData } = useGetEvent(parseInt(currentEvent));

  if (currentEvent)
    useEffect(() => {
      if (typeof eventData !== 'undefined') {
        setMultiDay(eventData.getEvent.isMultiDay);
      }
      const admin_times_modified = eventData?.getEvent.admin_times.map(
        (time) => ({
          id: time.id,
          start_time: time.start_time ? setTime(time.start_time) : null,
          gate_time: time.gate_time ? setTime(time.gate_time) : null,
          event_date: time.event_date,
        })
      );
      set_admin_times_modified(admin_times_modified);

      const off_sale_time_modified = eventData?.getEvent.off_sale_time
        ? setTime(eventData?.getEvent.off_sale_time)
        : null;
      set_off_sale_time_modified(off_sale_time_modified);

      const on_sale_time_modified = eventData?.getEvent.on_sale_time
        ? setTime(eventData?.getEvent.on_sale_time)
        : null;
      set_on_sale_time_modified(on_sale_time_modified);

      const registration_off_time_modified = eventData?.getEvent
        .registration_off_time
        ? setTime(eventData?.getEvent.registration_off_time)
        : null;
      set_registration_off_time_modified(registration_off_time_modified);
    }, [eventData]);

  const { data: matrixData } = useGetMatrices({
    variables: { input: { limit: '1000' } },
  });

  const matrices = matrixData?.getMatrices?.results ?? [];

  // if (!eventData?.getEvent && !newEvent) return null;

  const tabsConfig = [
    {
      id: 0,
      name: 'Dates and Times',
    },
    {
      id: 1,
      name: 'Tickets',
    },
    {
      id: 2,
      name: 'Event Details',
    },
    {
      id: 3,
      name: 'Waivers',
    },
    {
      id: 4,
      name: 'Financial',
    },
    // {
    //   id: 5,
    //   name: 'Registration',
    // },
    {
      id: 6,
      name: 'Lap Sponsorship',
    },
    {
      id: 7,
      name: 'Notes',
    },
    {
      id: 8,
      name: 'General Info',
    },
  ];

  if (process.env.REACT_APP_PLATFORM === 'tickethoss') {
    tabsConfig.splice(3, 1);
  }
  const [chosenTab, setChosenTab] = useState(tabsConfig[0]);

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  const initialValues = useMemo(() => {
    return getInitialValues(
      eventData?.getEvent,
      admin_times_modified,
      off_sale_time_modified,
      on_sale_time_modified,
      registration_off_time_modified,
      formValues
    );
  }, [
    eventData?.getEvent,
    admin_times_modified,
    off_sale_time_modified,
    on_sale_time_modified,
    registration_off_time_modified,
    formValues,
  ]);

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: theme.colors.background.light,
        minHeight: '90vh',
      }}
    >
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = 'Required';
            toast.error('Event Name missing');
            return errors;
          } else if (!values.start_date) {
            errors.start_date = 'Required';
            toast.error('Event Dates missing');
          } else if (!currentTrack && !values.track_id) {
            errors.track_id = 'Required';
            toast.error('Event Track missing');
          }

          if (!values.end_date) {
            errors.end_date = 'Required';
          }

          const ticketsWithDescriptions = [
            'admin_tickets',
            'admin_multiday_tickets',
            'admin_bundle_tickets',
            'admin_other_tickets',
          ];

          ticketsWithDescriptions.forEach((ticketType) => {
            if (
              !validateTicketDescriptionCharacterCounts(
                values[ticketType],
                DESCRIPTION_CHARACTER_LIMIT
              )
            ) {
              errors[ticketType] = descriptionError;
            }

            values[ticketType].forEach((ticket, index) => {
              if (
                ticketType === 'admin_tickets' ||
                ticketType === 'admin_multiday_tickets'
              ) {
                if (!ticket.start_date) {
                  errors[`${ticketType}.${index}.start_date`] = 'Required';
                  toast.error('Ticket Start Date missing');
                }
                if (ticketType === 'admin_multiday_tickets') {
                  if (!ticket.end_date) {
                    errors[`${ticketType}.${index}.end_date`] = 'Required';
                    toast.error('Ticket End Date missing');
                  }
                }
              }
              if (!ticket.name) {
                errors[`${ticketType}.${index}.name`] = 'Required';
                toast.error('Ticket name missing');
              } else if (!ticket.price && ticket.price !== 0) {
                errors[`${ticketType}.${index}.price`] = 'Required';
                toast.error('Ticket price missing');
              } else if (!ticket.color_code) {
                errors[`${ticketType}.${index}.color_code`] = 'Required';
                toast.error('Ticket Color Code missing');
              }
            });
          });

          values['lap_sponsorships'].forEach((lapSponsorship, index) => {
            if (!lapSponsorship.name) {
              errors[`lap_sponsorships.${index}.name`] = 'Required';
              toast.error('Lap Sponsorship name missing');
            } else if (!lapSponsorship.num_laps) {
              errors[`lap_sponsorships.${index}.num_laps`] = 'Required';
              toast.error('Lap Sponsorship number of laps missing');
            } else if (!lapSponsorship.price) {
              errors[`lap_sponsorships.${index}.price`] = 'Required';
              toast.error('Lap Sponsorship price missing');
            }
          });

          const faqErrors = values.faqs.reduce((errors, faq, index) => {
            const { answer, question } = faq;
            const error =
              !answer || !question
                ? {
                    ...(!answer ? { answer: 'Required' } : null),
                    ...(!question ? { question: 'Required' } : null),
                  }
                : undefined;
            return {
              ...errors,
              ...(error
                ? { [index]: error } && toast.error('Please check FAQ details')
                : null),
            };
          }, {});
          if (Object.keys(faqErrors).length > 0) errors.faqs = faqErrors;

          function getFeesErrors(fees) {
            return fees.reduce((errors, fee, index) => {
              const { fee_price, fee_amount, minimum, maximum } = fee;
              const error =
                !fee_price ||
                !fee_amount ||
                (!minimum && 0 !== minimum) ||
                !maximum
                  ? {
                      ...(!fee_price ? { fee_price: 'Required' } : null),
                      ...(!fee_amount ? { fee_amount: 'Required' } : null),
                      ...(!minimum ? { minimum: 'Required' } : null),
                      ...(!maximum ? { maximum: 'Required' } : null),
                    }
                  : undefined;
              return {
                ...errors,
                // If all fields are blank, we won't throw an error
                ...(error ? { [index]: error } : null),
              };
            }, {});
          }

          const otherFeesErrors = getFeesErrors(values.other_fees);
          if (Object.keys(otherFeesErrors).length > 0) {
            errors.other_fees = otherFeesErrors;
            toast.error('Please check other fee details');
          }

          const registrationFeesErrors = getFeesErrors(
            values.registration_fees
          );
          if (Object.keys(registrationFeesErrors).length > 0) {
            errors.registration_fees = registrationFeesErrors;
            toast.error('Please check registration fee details');
          }

          // if (Object.keys(errors).length) {
          //   toast.error('Please check event details');
          // }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let response;
          setSubmitting(true);

          const data = Object.entries(values).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value === '' ? null : value,
            }),
            {}
          );

          delete data['single_day'];
          delete data['multiday'];
          delete data['image'];
          delete data['promo_image'];
          delete data['facebook_image'];
          delete data['twitter_image'];
          delete data['schedule_detail_image'];
          delete data['track'];
          delete data['series'];

          //Extract the different type of tickets from the combined tickets with their order
          if (data['admin_ticket_view_key']) {
            data['admin_ticket_view'] = data['admin_ticket_view_key'].reduce(
              (acc, key) => {
                const tickets = data[key].map((ticket, index) => ({
                  ...getTicketWithOrders(ticket, index),
                }));

                return [...acc, ...tickets];
              },
              []
            );

            data['admin_tickets'] = data['admin_ticket_view'].filter(
              (item) => item.type === 'admin' && !item.isMultiDay
            );

            data['admin_multiday_tickets'] = data['admin_ticket_view'].filter(
              (item) =>
                (item.type === 'admin' || item.type === 'ticket') &&
                item.isMultiDay
            );
            if (process.env.REACT_APP_PLATFORM === 'tickethoss')
              data['admin_other_tickets'] = data['admin_ticket_view'].filter(
                (item) => item.type === 'other'
              );
            data['admin_bundle_tickets'] = data['admin_ticket_view'].filter(
              (item) => item.type === 'bundle'
            );
          }

          delete data['admin_ticket_view'];

          if (data['admin_ticket_view_key']) {
            data['admin_ticket_view_key'].forEach((key) => {
              delete data[key];
            });

            delete data['Unassigned'];
            delete data['admin_ticket_view_key'];
          }

          if (data['deleted_keys']) {
            data['deleted_keys'].forEach((key) => {
              delete data[key];
            });

            delete data['deleted_keys'];
          }

          if (
            data['admin_other_view'] &&
            process.env.REACT_APP_PLATFORM !== 'tickethoss' &&
            data['admin_other_tickets'].length > 0
          ) {
            data['admin_other_tickets'] = data['admin_other_view'].map(
              (ticket, index) => ({
                ...getTicketWithOrders(ticket, index),
              })
            );
          }
          delete data['admin_other_view'];

          if (typeof data['admin_tickets'] !== 'undefined')
            data['admin_tickets'].map((ticket) => {
              delete ticket['type'];
              if (ticket.category_id === 'Unassigned')
                delete ticket['category_id'];
              if (ticket.ticket_tiers && ticket.ticket_tiers.length > 0) {
                ticket.ticket_tiers = ticket.ticket_tiers.map((tier) => {
                  delete tier['__typename'];
                  delete tier['ticketType'];

                  return tier;
                });
              }
            });

          if (typeof data['admin_multiday_tickets'] !== 'undefined')
            data['admin_multiday_tickets'].map((ticket) => {
              delete ticket['type'];
              if (ticket.category_id === 'Unassigned')
                delete ticket['category_id'];
            });

          if (typeof data['admin_other_tickets'] !== 'undefined')
            data['admin_other_tickets'].map((ticket) => {
              delete ticket['type'];
              if (ticket.category_id === 'Unassigned')
                delete ticket['category_id'];
            });

          if (typeof data['admin_bundle_tickets'] !== 'undefined')
            data['admin_bundle_tickets'].map((ticket) => {
              delete ticket['type'];
              delete ticket['isMultiDay'];
              if (ticket.category_id === 'Unassigned')
                delete ticket['category_id'];

              console.log('bundle ticket', ticket);

              ticket.associated_tickets?.map((asso) => {
                delete asso['__typename'];
                if (asso['isNew']) {
                  delete asso['isNew'];
                  delete asso['id'];
                }
                if (!asso['quantity']) asso['quantity'] = 0;
              });
            });

          if (
            data['lap_sponsorships_with_order'] &&
            data['lap_sponsorships_with_order'].length > 0
          ) {
            data['lap_sponsorships'] = data['lap_sponsorships_with_order'].map(
              (sponsorship, index) => ({
                ...getLapsWithOrders(sponsorship, index),
              })
            );
          }
          delete data['lap_sponsorships_with_order'];

          if (data['infos_with_order'] && data['infos_with_order'].length > 0) {
            data['admin_infos'] = data['infos_with_order'].map(
              (info, index) => ({
                ...getInfosWithOrders(info, index),
              })
            );
          }
          delete data['infos'];
          delete data['infos_with_order'];

          if (typeof data['notes'] !== 'undefined')
            data['notes'].map((note) => {
              delete note['__typename'];
            });

          if (data['status'] === null) {
            data['status'] = 'draft';
          }

          if (data['capacity']) data['capacity'] = Number(data['capacity']);

          if (data['pos_settings']) {
            delete data[`pos_settings`]['__typename'];
          }

          if (data['access_code']) {
            delete data[`access_code`];
          } else {
            delete data[`access_code`];
            data['code'] = null;
            data['code_date'] = null;
            data['code_time'] = null;
          }

          if (currentEvent) {
            response = await updateEvent(
              getEventInput({
                data,
                currentTrack,
                currentEvent,
                currentSeries,
                action: 'update',
              })
            );
          } else {
            delete data['logo'];
            delete data['image'];
            delete data['facebook_image'];
            delete data['twitter_image'];
            response = await createEvent(
              getEventInput({
                data,
                currentTrack,
                currentEvent,
                currentSeries,
                action: 'create',
              }),
              //this is for the mutation to know how to update the store
              currentTrack ? 'track' : currentSeries ? 'series' : '',
              currentTrack ? currentTrack : currentSeries
            );
          }

          if (!response || response.errors) {
            errorMessage(response, response.errors.message);
            setSubmitting(false);
            return setErrors(response.errors);
          } else {
            successMessage();
            setSubmitting(false);
            history.goBack();
            // handleOutClick();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
        }) => {
          const changeSingleDayTicketStartDate = (date) => {
            if (values.admin_tickets.length === 0) return;
            // Change the start date of all single day tickets
            const newTickets = values.admin_tickets.map((ticket) => ({
              ...ticket,
              start_date: date,
            }));
            const newTicketView = values.admin_ticket_view.map(
              (ticketView) => ({
                ...ticketView,
                start_date: date,
              })
            );

            //Loop over the categories and change the start date of the tickets
            values.admin_ticket_view_key.forEach((key) => {
              let categories_tickets = values[key].map((ticket) => {
                return {
                  ...ticket,
                  start_date: date,
                };
              });

              setFieldValue(key, categories_tickets);
            });

            setFieldValue('admin_tickets', newTickets);
            setFieldValue('admin_ticket_view', newTicketView);
          };

          return (
            <form onSubmit={handleSubmit}>
              <div>
                <Header>
                  <EventHeader
                    values={values}
                    ENHandleChange={handleChange}
                    ENHandleBlur={handleBlur}
                    ENValues={values.name}
                    ENErrors={errors.name}
                    ENTrack={trackTitle}
                    EDSetFieldValue={setFieldValue}
                    SDValue={values.start_date}
                    SDErrors={errors.start_date}
                    EDValue={values.end_date}
                    EDErrors={errors.end_date}
                    eventId={parseInt(currentEvent)}
                    status_color={values.status_color}
                    editCheck={currentEvent}
                    isMultiDay={is_multiday}
                    trackTitle={trackTitle}
                    statusValues={values.status}
                  />
                </Header>
                <EventBody>
                  <TabContent>
                    <Tabs>
                      {!isMobile ? (
                        tabsConfig.map((tab, index) =>
                          tab.id === 3 ? (
                            process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
                              <Tab
                                onClick={(e) => handleClick(e)}
                                active={active === tab.id}
                                id={tab.id}
                              >
                                {tab.name}
                              </Tab>
                            )
                          ) : tab.id === 6 ? (
                            process.env.REACT_APP_PLATFORM === 'tickethoss' && (
                              <Tab
                                onClick={(e) => handleClick(e)}
                                active={active === tab.id}
                                id={tab.id}
                              >
                                {tab.name}
                              </Tab>
                            )
                          ) : (
                            <Tab
                              onClick={(e) => handleClick(e)}
                              active={active === tab.id}
                              id={tab.id}
                            >
                              {tab.name}
                            </Tab>
                          )
                        )
                      ) : (
                        <div style={{ width: '90%', margin: '0 5vw' }}>
                          <AutoSuggest
                            value={chosenTab}
                            onChange={(value) => {
                              setChosenTab(value);
                              setActive(value.value);
                            }}
                            onBlur={() => {
                              return;
                            }}
                            closeMenuOnSelect
                            options={tabsConfig.map((item) => {
                              return {
                                value: item.id,
                                label: item.name,
                              };
                            })}
                          />
                          <Spacer size={10} />
                        </div>
                      )}

                      <AddUpdateBtn
                        type="submit"
                        style={{}}
                        disabled={isSubmitting}
                        block
                      >
                        {currentEvent ? 'Update Event' : 'Add Event'}
                      </AddUpdateBtn>
                    </Tabs>

                    <>
                      <Content active={active === 0}>
                        <Row>
                          <Col>
                            <SectionTitle>Event Date</SectionTitle>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div style={{ display: 'flex' }}>
                              <div style={{ marginRight: '35px' }}>
                                <Checkbox
                                  name="single_day"
                                  className="singleDay"
                                  checked={
                                    is_multiday === null ? false : !is_multiday
                                  }
                                  onChange={(event) => {
                                    const value = event.target?.checked;
                                    handleChange({
                                      target: {
                                        name: 'single_day',
                                        value,
                                      },
                                    });
                                    if (value) {
                                      handleChange({
                                        target: {
                                          name: 'multiday',
                                          value: false,
                                        },
                                      });
                                      handleChange({
                                        target: {
                                          name: 'end_date',
                                          value: values.start_date,
                                        },
                                      });
                                      setMultiDay(false);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    errors.hide_fee &&
                                    touched.hide_fee &&
                                    errors.hide_fee
                                  }
                                  rightText="Single Day Event"
                                />
                              </div>
                              <div>
                                <Checkbox
                                  name="multiday"
                                  checked={is_multiday}
                                  onChange={(event) => {
                                    const value = event.target?.checked;
                                    handleChange({
                                      target: {
                                        name: 'multiday',
                                        value,
                                      },
                                    });
                                    if (value) {
                                      handleChange({
                                        target: {
                                          name: 'single_day',
                                          value: false,
                                        },
                                      });
                                      setMultiDay(true);
                                    } else {
                                      setMultiDay(false);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    errors.hide_fee &&
                                    touched.hide_fee &&
                                    errors.hide_fee
                                  }
                                  rightText="Multi Day Event"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Spacer size={15} />
                        <ResponsiveRow>
                          <ResponsiveCol>
                            {is_multiday !== null && (
                              <DatePicker
                                id="start_date"
                                name="start_date"
                                label="Event Start Date"
                                type="date"
                                is_multiday={is_multiday}
                                test
                                onChange={(_, value) => {
                                  const oldValue = values.start_date;
                                  const now = moment().format('MM-DD-YYYY');

                                  setFieldValue('start_date', value);
                                  if (values.end_date === oldValue)
                                    setFieldValue('end_date', value);
                                  if (!values.sale_start)
                                    setFieldValue('sale_start', now);
                                  setFieldValue('sale_end', value);
                                  changeSingleDayTicketStartDate(value);
                                }}
                                value={values.start_date}
                                error={errors.start_date}
                                isResponsive={true}
                              />
                            )}
                          </ResponsiveCol>
                          <ResponsiveCol>
                            <div
                              onClick={
                                values.start_date === ''
                                  ? () =>
                                      toast.error(
                                        'Event start date is not selected'
                                      )
                                  : null
                              }
                            >
                              {is_multiday && (
                                <DatePicker
                                  id="end_date"
                                  name="end_date"
                                  label="Event End Date"
                                  type="date"
                                  onChange={(_, value) => {
                                    setFieldValue('end_date', value);
                                    setFieldValue('sale_end', value);
                                  }}
                                  value={values.end_date}
                                  error={errors.end_date}
                                  disabled={
                                    values.start_date === '' ? true : false
                                  }
                                  isResponsive={true}
                                />
                              )}
                            </div>
                          </ResponsiveCol>
                          <Col></Col>
                          <Col></Col>
                        </ResponsiveRow>

                        {is_multiday !== null && (
                          <>
                            <Spacer size={20} />
                            <SectionTitle>On Sale Information</SectionTitle>
                            <ResponsiveRow>
                              <ResponsiveCol>
                                <DatePicker
                                  id="sale_start"
                                  name="sale_start"
                                  label="Ticket Sales Start"
                                  type="date"
                                  test
                                  onChange={setFieldValue}
                                  value={values.sale_start}
                                  error={
                                    errors.sale_start &&
                                    touched.sale_start &&
                                    errors.sale_start
                                  }
                                  isResponsive={true}
                                />
                              </ResponsiveCol>
                              <ResponsiveCol>
                                <div
                                  onClick={
                                    values.sale_start === ''
                                      ? () =>
                                          toast.error(
                                            'Ticket sales start date is not selected'
                                          )
                                      : null
                                  }
                                >
                                  <DatePicker
                                    id="sale_end"
                                    name="sale_end"
                                    label="Ticket Sales End"
                                    type="date"
                                    onChange={setFieldValue}
                                    value={values.sale_end}
                                    error={
                                      errors.sale_end &&
                                      touched.sale_end &&
                                      errors.sale_end
                                    }
                                    disabled={
                                      values.sale_start === '' ? true : false
                                    }
                                    isResponsive={true}
                                  />
                                </div>
                              </ResponsiveCol>
                              <ResponsiveCol>
                                <DatePicker
                                  id={'on_sale_time'}
                                  type="time"
                                  label={`Ticket on Sale Time`}
                                  name="on_sale_time"
                                  value={values.on_sale_time}
                                  onChange={(name, event) => {
                                    handleChange({
                                      target: {
                                        name,
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                  isResponsive={true}
                                />
                              </ResponsiveCol>
                              <ResponsiveCol>
                                <DatePicker
                                  id={'off_sale_time'}
                                  type="time"
                                  label={`Ticket Off Sale Time`}
                                  name="off_sale_time"
                                  value={values.off_sale_time}
                                  onChange={(name, event) => {
                                    handleChange({
                                      target: {
                                        name,
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                  isResponsive={true}
                                />
                              </ResponsiveCol>
                            </ResponsiveRow>
                            <Spacer size={18} />

                            <Spacer size={18} />
                            <ResponsiveRow>
                              <ResponsiveCol>
                                <DateTimeRepeater
                                  error={errors.admin_times}
                                  dates={values.admin_times || []}
                                  values={values}
                                  eventSD={values.start_date}
                                  eventED={values.end_date}
                                  onChange={(value) => {
                                    handleChange({
                                      target: { name: 'admin_times', value },
                                    });
                                  }}
                                  isResponsive={true}
                                />
                              </ResponsiveCol>
                              <ResponsiveCol>
                                <Spacer size={44} />

                                <Dropzone
                                  id="schedule_detail_image_id"
                                  files={
                                    values.schedule_detail_image ||
                                    values.schedule_detail_image_id
                                      ? [
                                          {
                                            name: values.schedule_detail_image_id,
                                            preview:
                                              values.schedule_detail_image
                                                ? values.schedule_detail_image
                                                : values.schedule_detail_image_id
                                                ? 'https://d260w0yqhyrp8h.cloudfront.net/PDF_file_icon.svg.png'
                                                : '',
                                          },
                                        ]
                                      : []
                                  }
                                  onChange={setFieldValue}
                                  setError={setFieldError}
                                  error={
                                    errors.schedule_detail_image_id &&
                                    touched.schedule_detail_image_id &&
                                    errors.schedule_detail_image_id
                                  }
                                  label="Schedule Details Image"
                                />
                                <Spacer size={18} />
                                <Input
                                  id="schedule_detail_url"
                                  label="Schedule Details Link"
                                  placeholder="Schedule Link"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.schedule_detail_url}
                                  error={
                                    errors.schedule_detail_url &&
                                    touched.schedule_detail_url &&
                                    errors.schedule_detail_url
                                  }
                                />

                                <Spacer size={18} />

                                <Input
                                  inputStyle={{ height: 400 }}
                                  id="schedule_details"
                                  as="textarea"
                                  label="Schedule Details"
                                  placeholder="Schedule Details"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.schedule_details}
                                  error={
                                    errors.schedule_details &&
                                    touched.schedule_details &&
                                    errors.schedule_details
                                  }
                                />
                                <Spacer size={18} />
                              </ResponsiveCol>
                            </ResponsiveRow>
                          </>
                        )}
                      </Content>
                      <Content active={active === 1}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            padding: '5px',
                          }}
                        >
                          <Tickets
                            tickets={values}
                            errors={errors}
                            onChange={(name, value) => {
                              typeof name === 'string' &&
                                handleChange({
                                  target: { name: name, value },
                                });
                            }}
                            handleSort={(name, value) => {
                              handleChange({
                                target: {
                                  name: name,
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </Content>
                      <Content active={active === 2}>
                        <ResponsiveRow>
                          <ResponsiveCol
                            style={{
                              alignSelf: 'flex-start',
                            }}
                          >
                            <Input
                              id="name"
                              name="name"
                              label="Event Name"
                              placeholder="Event Name"
                              onChange={handleChange ? handleChange : ''}
                              onBlur={handleBlur ? handleBlur : ''}
                              value={values.name ? values.name : ''}
                              error={errors.name ? errors.name : ''}
                            />
                            <Spacer size={28} />
                            {currentSeries ? (
                              <>
                                <Tracks
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldValue}
                                />
                                <Spacer size={28} />
                              </>
                            ) : currentTrack ? (
                              <SeriesSelect isMulti />
                            ) : (
                              <>
                                <Tracks
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldValue}
                                />
                                <Spacer size={28} />
                                <SeriesSelect isMulti />
                              </>
                            )}
                            <Spacer size={18} />
                            <Dropzone
                              id="logo_id"
                              files={
                                values.logo
                                  ? [
                                      {
                                        name: values.logo_id,
                                        preview: values.logo,
                                      },
                                    ]
                                  : []
                              }
                              onChange={setFieldValue}
                              setError={setFieldError}
                              error={
                                errors.logo_id &&
                                touched.logo_id &&
                                errors.logo_id
                              }
                              label="Event Logo"
                            />
                            <Dropzone
                              id="image_id"
                              files={
                                values.image
                                  ? [
                                      {
                                        name: values.image_id,
                                        preview: values.image,
                                      },
                                    ]
                                  : []
                              }
                              onChange={setFieldValue}
                              setError={setFieldError}
                              error={
                                errors.image_id &&
                                touched.image_id &&
                                errors.image_id
                              }
                              label="Event Image"
                            />
                            <Spacer size={24} />
                            <Label>Event Socials</Label>
                            <RowSection leftText="Twitter">
                              <Input
                                id="twitter"
                                placeholder="Twitter Handle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.twitter}
                                error={
                                  errors.twitter &&
                                  touched.twitter &&
                                  errors.twitter
                                }
                              />
                            </RowSection>
                            <Spacer size={10} />
                            <RowSection leftText="Facebook">
                              <Input
                                id="facebook"
                                placeholder="Facebook Link"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.facebook}
                                error={
                                  errors.facebook &&
                                  touched.facebook &&
                                  errors.facebook
                                }
                              />
                            </RowSection>
                            <Spacer size={10} />
                            <RowSection leftText="Instagram">
                              <Input
                                id="instagram"
                                placeholder="Instagram Handle"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.instagram}
                                error={
                                  errors.instagram &&
                                  touched.instagram &&
                                  errors.instagram
                                }
                              />
                            </RowSection>

                            <Spacer size={24} />
                            <Input
                              id="admin_website_url"
                              label="Event Website"
                              placeholder="Website URL"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.admin_website_url}
                              error={
                                errors.admin_website_url &&
                                touched.admin_website_url &&
                                errors.admin_website_url
                              }
                            />
                            <Spacer size={24} />
                            <Input
                              id="admin_schedule_url"
                              label="Event Schedule"
                              placeholder="Schedule URL"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.admin_schedule_url}
                              error={
                                errors.admin_schedule_url &&
                                touched.admin_schedule_url &&
                                errors.admin_schedule_url
                              }
                            />
                            <Spacer size={18} />
                            <Input
                              id="capacity"
                              label="Event Capacity"
                              placeholder="Event Capacity"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.capacity}
                              error={
                                errors.capacity &&
                                touched.capacity &&
                                errors.capacity
                              }
                            />
                            <Spacer size={18} />
                            <Input
                              id="url"
                              label="Video"
                              placeholder="Video URL"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.url}
                              error={errors.url && touched.url && errors.url}
                            />

                            <Spacer size={18} />
                            <Input
                              id="info_video_url"
                              label="Know Before You Go"
                              placeholder="URL"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.info_video_url}
                              error={
                                errors.info_video_url &&
                                touched.info_video_url &&
                                errors.info_video_url
                              }
                            />

                            <Spacer size={18} />
                            <Dropzone
                              id="promo_image_id"
                              files={
                                values.promo_image
                                  ? [
                                      {
                                        name: values.promo_image_id,
                                        preview: values.promo_image,
                                      },
                                    ]
                                  : []
                              }
                              onChange={setFieldValue}
                              setError={setFieldError}
                              error={
                                errors.promo_image_id &&
                                touched.promo_image_id &&
                                errors.promo_image_id
                              }
                              label="Promo Image"
                            />

                            <Spacer size={18} />

                            <Dropzone
                              id="facebook_image_id"
                              files={
                                values.facebook_image
                                  ? [
                                      {
                                        name: values.facebook_image_id,
                                        preview: values.facebook_image,
                                      },
                                    ]
                                  : []
                              }
                              onChange={setFieldValue}
                              setError={setFieldError}
                              error={
                                errors.facebook_image_id &&
                                touched.facebook_image_id &&
                                errors.facebook_image_id
                              }
                              label="Social Share Image"
                            />

                            <Spacer size={18} />

                            {process.env.REACT_APP_PLATFORM === 'pitpay' && (
                              <Checkbox
                                name="show_tickethoss"
                                checked={!!values.show_tickethoss}
                                onChange={(event) => {
                                  const value = event.target?.checked;
                                  handleChange({
                                    target: {
                                      name: 'show_tickethoss',
                                      value,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                error={
                                  errors.show_tickethoss &&
                                  touched.show_tickethoss &&
                                  errors.show_tickethoss
                                }
                                rightText="Show Tickethoss CTA"
                              />
                            )}
                            {process.env.REACT_APP_PLATFORM ===
                              'tickethoss' && (
                              <Fragment>
                                <Checkbox
                                  name="show_pitpay"
                                  checked={!!values.show_pitpay}
                                  onChange={(event) => {
                                    const value = event.target?.checked;
                                    handleChange({
                                      target: {
                                        name: 'show_pitpay',
                                        value,
                                      },
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    errors.show_pitpay &&
                                    touched.show_pitpay &&
                                    errors.show_pitpay
                                  }
                                  rightText="Show Pit Pay CTA"
                                />
                              </Fragment>
                            )}

                            <Spacer size={18} />
                            <Checkbox
                              name="pos"
                              checked={!!values.pos}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'pos',
                                    value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              error={errors.pos && touched.pos && errors.pos}
                              rightText="Custom Point of Sale Settings"
                            />

                            {values.pos && (
                              <Fragment>
                                <Spacer size={18} />
                                <PointOfSaleSettings
                                  handleChange={(target) => {
                                    const { name, value } = target.target;

                                    const newSettings = {
                                      ...values.pos_settings,
                                      [name]: value,
                                    };

                                    typeof name === 'string' &&
                                      handleChange({
                                        target: {
                                          name: 'pos_settings',
                                          value: newSettings,
                                        },
                                      });
                                  }}
                                  mainValues={values}
                                  handleBlur={handleBlur}
                                  errors={errors}
                                  touched={touched}
                                />
                              </Fragment>
                            )}

                            <Spacer size={18} />
                            <Checkbox
                              name="access_code"
                              checked={!!values.access_code}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'access_code',
                                    value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.access_code &&
                                touched.access_code &&
                                errors.access_code
                              }
                              rightText="Set Access Code"
                            />

                            {values.access_code && (
                              <Fragment>
                                <Spacer size={18} />
                                <Input
                                  id="code"
                                  label="Access Code"
                                  placeholder="Code"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.code}
                                  error={
                                    errors.code && touched.code && errors.code
                                  }
                                />

                                <Spacer size={18} />
                                <DatePicker
                                  id="code_date"
                                  name="code_date"
                                  label="End Date"
                                  type="date"
                                  test
                                  onChange={setFieldValue}
                                  value={values.code_date}
                                  error={
                                    errors.code_date &&
                                    touched.code_date &&
                                    errors.code_date
                                  }
                                  isResponsive={true}
                                />

                                <Spacer size={18} />
                                <DatePicker
                                  id={'code_time'}
                                  type="time"
                                  label={`End Time`}
                                  name="code_time"
                                  value={values.code_time}
                                  onChange={(name, event) => {
                                    handleChange({
                                      target: {
                                        name,
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                  isResponsive={true}
                                />
                              </Fragment>
                            )}

                            <Spacer size={18} />
                            <Input
                              id="url"
                              label="Video"
                              placeholder="Video URL"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.url}
                              error={errors.url && touched.url && errors.url}
                            />

                            <Spacer size={18} />
                            <Input
                              id="seats_id"
                              label="Seats Event ID"
                              placeholder="Seats Event ID"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.seats_id}
                              error={
                                errors.seats_id &&
                                touched.seats_id &&
                                errors.seats_id
                              }
                            />
                          </ResponsiveCol>
                          <ResponsiveCol
                            style={{
                              alignSelf: 'flex-start',
                            }}
                          >
                            <Spacer size={24} />
                            <Input
                              inputStyle={{ height: 600 }}
                              id="about"
                              as="textarea"
                              label="Event Info"
                              placeholder="Event Info"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.about}
                              error={
                                errors.about && touched.about && errors.about
                              }
                            />
                            <Spacer size={18} />
                            <FaqList />
                          </ResponsiveCol>
                        </ResponsiveRow>
                      </Content>
                      <Content active={active === 3}>
                        <ResponsiveRow>
                          <div style={{ width: '50%' }}>
                            <WaiversRepeater
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errors={errors}
                              values={values}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldValue}
                              currentTrack={currentTrack}
                              currentSeries={currentSeries}
                              currentEvent={currentEvent}
                              id={currentEvent ?? currentTrack}
                            />
                          </div>
                        </ResponsiveRow>
                      </Content>
                      <Content active={active === 4}>
                        <ResponsiveRow>
                          <ResponsiveCol>
                            <Account
                              values={values}
                              errors={errors}
                              touched={touched}
                              id={currentEvent ?? currentTrack}
                              setFieldValue={setFieldValue}
                              currentSeries={currentSeries}
                              currentTrack={currentTrack}
                              currentEvent={currentEvent}
                              setFieldTouched={setFieldValue}
                            />
                            <Spacer size={50} />
                          </ResponsiveCol>
                          {/* <Ownership
                              values={values}
                              errors={errors}
                              touched={touched}
                              id={currentEvent ?? currentTrack}
                              setFieldValue={setFieldValue}
                              currentSeries={currentSeries}
                              currentTrack={currentTrack}
                              currentEvent={currentEvent}
                              setFieldTouched={setFieldValue}
                            />
                            <Spacer size={18} /> */}
                          {/* <AutoSuggest
                              id="matrix_id"
                              label="Fee Matrix"
                              closeMenuOnSelect
                              isClearable
                              options={matrices.map((matrix) => ({
                                value: matrix.id,
                                label: matrix.name,
                              }))}
                              onChange={(value) =>
                                setFieldValue('matrix_id', value)
                              }
                              value={values.matrix_id}
                            />
                            {errors.matrix_id && (
                              <ErrorText fontSize={12}>
                                {errors.matrix_id}
                              </ErrorText>
                            )}
                            <Spacer size={18} /> */}
                          <ResponsiveCol>
                            <Checkbox
                              name="marketing_fee"
                              checked={!!values.marketing_fee}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'marketing_fee',
                                    value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.marketing_fee &&
                                touched.marketing_fee &&
                                errors.marketing_fee
                              }
                              rightText="Collect Marketing Fees?"
                            />
                            <Spacer size={10} />
                            <Checkbox
                              name="international_fee"
                              checked={!!values.international_fee}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'international_fee',
                                    value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.international_fee &&
                                touched.international_fee &&
                                errors.international_fee
                              }
                              rightText="Collect International Fees?"
                            />
                            <Spacer size={10} />
                            <Checkbox
                              name="hide_fee"
                              checked={!!values.hide_fee}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'hide_fee',
                                    value,
                                  },
                                });
                                if (value) {
                                  handleChange({
                                    target: {
                                      name: 'marketing_fee',
                                      value: false,
                                    },
                                  });
                                }
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.hide_fee &&
                                touched.hide_fee &&
                                errors.hide_fee
                              }
                              rightText="Promoter Absorbs Fees?"
                            />
                          </ResponsiveCol>
                        </ResponsiveRow>
                        <ResponsiveCol style={{ width: '100%' }}>
                          <Spacer size={50} />
                          <SectionTitle>Event Organizer Details</SectionTitle>
                          <div style={{ width: !isMobile ? '50%' : '100%' }}>
                            <Input
                              id="promoter_name"
                              name="promoter_name"
                              label="Promoter Name"
                              placeholder="Promoter Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.promoter_name}
                              error={errors.promoter_name}
                            />
                            <Spacer size={15} />
                            <Input
                              id="promoter_email"
                              name="promoter_email"
                              label="Promoter Email"
                              placeholder="promoter@email.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.promoter_email}
                              error={errors.promoter_email}
                            />
                            <Spacer size={15} />
                            <Input
                              id="promoter_phone"
                              name="promoter_phone"
                              label="Promoter Phone Number"
                              placeholder="Phone Number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.promoter_phone}
                              error={errors.promoter_phone}
                            />
                            <Spacer size={15} />
                            <Input
                              id="promoter_organization"
                              name="promoter_organization"
                              label="Promoter Organization"
                              placeholder="Promoter Organization"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.promoter_organization}
                              error={errors.promoter_organization}
                            />
                            <Spacer size={15} />
                            <Input
                              id="promoter_website"
                              name="promoter_website"
                              label="Promoter Website"
                              placeholder="Promoter Website"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.promoter_website}
                              error={errors.promoter_website}
                            />
                          </div>
                          {/* <FeesRepeater
                              feeType="other"
                              error={errors.other_fees}
                              fees={values.other_fees || []}
                              onChange={(value) => {
                                handleChange({
                                  target: { name: 'other_fees', value },
                                });
                              }}
                              errors={errors.other_fees}
                            />
                            <Spacer size={24} />
                            <FeesRepeater
                              feeType="registration"
                              error={errors.registration_fees}
                              fees={values.registration_fees || []}
                              onChange={(value) => {
                                handleChange({
                                  target: { name: 'registration_fees', value },
                                });
                              }}
                              errors={errors.registration_fees}
                            /> */}
                        </ResponsiveCol>
                      </Content>
                      <Content active={active === 5}>
                        <ResponsiveRow>
                          <div style={{ width: '50%' }}>
                            <Input
                              id="registration_email"
                              name="registration_email"
                              label="Registration Email"
                              placeholder="test@email.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.registration_email}
                              error={errors.registration_email}
                            />
                          </div>
                          <Spacer size={18} />
                        </ResponsiveRow>
                      </Content>
                      <Content active={active === 6}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            padding: '5px',
                          }}
                        >
                          <LapSponsorshipEvent
                            onChange={({ target, order }) => {
                              const newSponsorships =
                                values.lap_sponsorships.map(
                                  (sponsorship, sponsorshipIndex) =>
                                    sponsorship.order === order
                                      ? {
                                          ...sponsorship,
                                          [target.name]: target.value,
                                        }
                                      : sponsorship
                                );
                              const newSponsorshipsWithOrder =
                                values.lap_sponsorships_with_order.map(
                                  (sponsorship) =>
                                    sponsorship.order === order
                                      ? {
                                          ...sponsorship,
                                          [target.name]: target.value,
                                        }
                                      : sponsorship
                                );
                              handleChange({
                                target: {
                                  name: 'lap_sponsorships',
                                  value: newSponsorships,
                                },
                              });
                              handleChange({
                                target: {
                                  name: 'lap_sponsorships_with_order',
                                  value: newSponsorshipsWithOrder,
                                },
                              });
                            }}
                            onChangeSponsorship={(name, value) => {
                              handleChange({
                                target: {
                                  name: name,
                                  value,
                                },
                              });
                            }}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            lapSponsorships={values.lap_sponsorships}
                            handleSort={(name, value) => {
                              handleChange({
                                target: {
                                  name: name,
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </Content>
                      <Content active={active === 7}>
                        <div style={{ display: 'flex' }}>
                          {/* <div style={{ width: '40%' }}>
                            <Checkbox
                              name="hide_fee"
                              checked={!!values.hide_fee}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'hide_fee',
                                    value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.hide_fee &&
                                touched.hide_fee &&
                                errors.hide_fee
                              }
                              rightText="Promoter Absorbs Fees"
                            />
                            <Spacer size={20}></Spacer>
                            <Checkbox
                              name="international_fee"
                              checked={!!values.international_fee}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'international_fee',
                                    value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.international_fee &&
                                touched.international_fee &&
                                errors.international_fee
                              }
                              rightText="Collect Int'l Fees"
                            />
                            <Spacer size={20}></Spacer>
                            <Checkbox
                              name="marketing_fee"
                              checked={!!values.marketing_fee}
                              onChange={(event) => {
                                const value = event.target?.checked;
                                handleChange({
                                  target: {
                                    name: 'marketing_fee',
                                    value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.marketing_fee &&
                                touched.marketing_fee &&
                                errors.marketing_fee
                              }
                              rightText="1% Cash Back"
                            />
                            </div> 
                            <div style={{ width: '20%' }}></div>
                          */}
                          <div
                            style={{
                              width: !isMobile ? '60%' : '90%',
                              margin: 20,
                            }}
                          >
                            <NoteList />
                            <Spacer size={20} />
                            {currentEvent && values.added_by && (
                              <h2>Created By : {values.added_by}</h2>
                            )}
                            <Spacer size={10} />
                            {currentEvent && values.updated_by && (
                              <h2>Updated By : {values.updated_by}</h2>
                            )}
                            <Spacer size={20} />
                          </div>
                        </div>
                      </Content>

                      <Content active={active === 8}>
                        <ResponsiveRow>
                          <GeneralInfo
                            values={values}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            props={props}
                          />
                        </ResponsiveRow>
                      </Content>
                    </>
                  </TabContent>
                  {/* <EditEventSidebar
                    addedByValues={values.added_by}
                    addedByErrors={errors}
                    addedByTouched={touched}
                    addedBySetFieldValue={setFieldValue}
                    addedByCurrentSeries={currentSeries}
                    addedSetFieldTouched={setFieldValue}
                    statusOptions={[
                      { label: 'Draft', value: 'draft' },
                      { label: 'Published', value: 'published' },
                      { label: 'Postponed', value: 'postponed' },
                      { label: 'Cancelled', value: 'cancelled' },
                    ]}
                    statusValues={values.status}
                    statusHandleChange={handleChange}
                    statusHandleBlur={handleBlur}
                    currentEvent={currentEvent}
                    isSubmitting={isSubmitting}
                  /> */}
                </EventBody>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default compose(CreateEvent, UpdateEvent)(AddEvents);
