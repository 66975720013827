import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import Loading from 'components/Loading';
import { TransactionModal as Modal } from 'components/Modal';
import Text from 'components/Text';
import { useGetTrackTransaction } from 'components/Transactions/gql/useGetPurchase';
import { RefundModal } from 'components/Transactions/TransactionModal/RefundModal';
import { TransactionCreditModal } from 'components/Transactions/TransactionModal/TransactionCreditModal';
import { useMobileDelivery } from 'pages/app/Cart/Components/gql/queries/useMobileDelivery';
import { PrintingModal } from 'pages/app/Cart/Components/PrintingModal';
import { ReaderIpModal } from 'pages/app/Cart/Components/ReaderIpModal';
import { TitleContainer } from 'pages/track/Contact/style';
import { successMessage } from 'shared/alerts';
import { useCart } from 'shared/CartContext';
import Financials from './Components/Financials';
import GeneralInfo from './Components/GeneralInfo';
import PrintReceipt from './Components/PrintReceipt';
import PrintTickets from './Components/PrintTickets';
import Tickets from './Components/Tickets';

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;

  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

const RefundButtonWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const RefundButton = styled.button`
  border-width: 3px;
  border-style: solid;
  border-color: #fa4616;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  color: #fa4616;
  font-size: 22px;
  font-family: 'Barlow Condensed';
  background-color: #fff;
  width: 100%;
  display: block;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    props.disabled
      ? `border-width: 3px;
  border-style: solid;
  border-color: #ccc; color: #ccc; pointer-events: none`
      : ''};

  &:hover {
    background: rgba(250, 70, 22, 0.1) !important;
  }

  a {
    color: #fa4616;
    font-family: 'Barlow Condensed';
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;

    ${(props) => (props.disabled ? `pointer-events: none;` : '')};
  }
`;

const PhoneContainer = styled.div`
  & .PhoneInput {
    background: ${(props) => props.theme.colors.white};
    padding: 0.4em 0.8em;
    box-sizing: border-box;
    & .PhoneInputInput {
      color: ${(props) => props.theme.colors.secondary};
      background: ${(props) => props.theme.colors.white};
      flex: 1 1;
      outline: none;
      min-width: 0;
      font-family: 'Barlow Condensed';
      font-weight: 600;
      border: 0;
      text-align: center;
      box-shadow: inset 0 0 0 2px #888888;
      border-radius: 8px;
      margin: 6px;
      padding: 10px;
      font-size: 35px;
      max-width: 225px;
    }
    & .PhoneInputInput::placeholder {
      word-spacing: -3px;
      letter-spacing: 3px;
      font-size: 41px;
      font-weight: 800;
    }

    &.PhoneInput--focus {
      border-color: ${(props) => props.theme.colors.primary};
      .PhoneInputInput {
        outline: none;
      }
    }
    .PhoneInputCountry {
      .PhoneInputCountrySelectArrow {
        color: ${(props) => props.theme.colors.primary};
        border-right-width: 3px;
        border-bottom-width: 3px;
      }
    }
  }
`;

const formValues = {
  cellphone: '',
  country_code: '',
  calling_code: '',
  send_receipt: false,
};

const TransactionDetail = (props) => {
  const { match, location } = props;
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const { printer, connectionStatus } = useCart();

  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openCreditModal, setOpenCreditModal] = useState(false);

  const [isPrinting, setIsPrinting] = useState(false);

  const [isReaderIpVisible, setIsReaderIpVisible] = useState(false);

  const employeeAdmin = location.pathname.includes('/admin-employee/');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const theme = useTheme();

  const { data, loading, refetch } = useGetTrackTransaction(
    match.params.id,
    true
  );
  const mobileDelivery = useMobileDelivery();

  const transaction = data?.getTrackTransaction;

  useEffect(() => {
    // Trigger IP modal if print option is enabled for any of the two options
    if (
      transaction?.event?.pos_settings?.ticket_delivery === 'print' ||
      transaction?.event?.pos_settings?.receipt_delivery === 'print'
    )
      setIsReaderIpVisible(true);
  }, [transaction]);

  const isAllRefunded =
    transaction?.users &&
    transaction?.users.length &&
    transaction?.users
      .map((person) => person.tickets.map((ticket) => ticket.refunded).flat())
      .flat()
      .every((refundedTicket) => refundedTicket === true);

  const isCredited =
    transaction?.users &&
    transaction?.users.length &&
    transaction?.users
      .map((person) => person.tickets.map((ticket) => ticket.credited).flat())
      .flat()
      .some((creditedTicket) => creditedTicket === true);

  const openRefund = () => {
    setOpenRefundModal(true);
    setCurrentTransaction(transaction);
  };

  const openCredit = () => {
    setOpenCreditModal(true);
    setCurrentTransaction(transaction);
  };

  const close = () => {
    setCurrentTransaction(null);
    if (openRefundModal) setOpenRefundModal(false);
    if (openCreditModal) setOpenCreditModal(false);

    refetch();
  };

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <ContainerHeader>
        <img
          id="logo"
          src="/images/th-simple-logo.png"
          width="200"
          height="100"
          style={{ display: 'none' }}
          alt="print-logo"
        />
        <TitleContainer>
          <Text
            type="heading"
            color="#3C4144"
            inlineStyle={{ marginRight: 25 }}
          >
            {transaction
              ? `Transaction Details - Order # ${transaction.id}`
              : 'Transaction Details'}
          </Text>
        </TitleContainer>
        <ButtonWrapper>
          {transaction?.event?.pos_settings &&
            transaction?.event?.pos_settings?.ticket_delivery === 'mobile' && (
              <Button
                buttonStyle={
                  isMobile
                    ? { width: '100px', margin: '0 10px' }
                    : { margin: '0 10px' }
                }
                style
                onClick={() => {
                  setOpenTicketModal(true);
                }}
              >
                DELIVER TO MOBILE
              </Button>
            )}
          {transaction?.event?.pos_settings &&
            transaction?.event?.pos_settings?.ticket_delivery === 'print' && (
              <PrintTickets
                printer={printer}
                connectionStatus={connectionStatus}
                transaction={transaction}
                // onClick={() => {
                //   setIsReaderIpVisible(true);
                // }}
                setIsPrinting={setIsPrinting}
              />
            )}
          {transaction?.event?.pos_settings &&
            transaction?.event?.pos_settings?.receipt_delivery === 'print' && (
              <PrintReceipt
                printer={printer}
                connectionStatus={connectionStatus}
                transaction={transaction}
                // onClick={() => {
                //   setIsReaderIpVisible(true);
                // }}
                setIsPrinting={setIsPrinting}
              />
            )}
        </ButtonWrapper>
      </ContainerHeader>
      <Financials transaction={transaction} />
      <GeneralInfo transaction={transaction} />
      <Tickets transaction={transaction} {...props} />

      {!employeeAdmin ? (
        <RefundButtonWrapper>
          <RefundButton
            disabled={isAllRefunded && !isCredited}
            onClick={openRefund}
          >
            Issue Refund
          </RefundButton>
          <RefundButton disabled={isAllRefunded} onClick={openCredit}>
            Issue Credit
          </RefundButton>
        </RefundButtonWrapper>
      ) : null}

      <Modal
        isVisible={openTicketModal}
        hideModal={() => setOpenTicketModal(false)}
        title={'Mobile Ticket Delivery'}
        maxWidth={600}
      >
        <Formik
          initialValues={formValues}
          validate={(values) => {
            const errors = {};

            if (!values.cellphone) {
              errors.cellphone = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
              const result = mobileDelivery({
                cellphone: values.cellphone,
                calling_code: values.calling_code ?? '+1',
                purchase_id: transaction.id,
                text_receipt: values.send_receipt,
              });

              if (result) {
                setOpenTicketModal(false);
                successMessage('Ticket sent successfully');
              }
            } catch (e) {
              console.error(e);
            }
          }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingInline: 20,
                    marginBottom: 30,
                    height: 80,
                    alignItems: 'center',
                  }}
                >
                  <PhoneContainer theme={theme}>
                    <PhoneInput
                      id="cellphone"
                      placeholder="___ ___ ____"
                      size={25}
                      onBlur={handleBlur}
                      defaultCountry={'US'}
                      onChange={(value) => {
                        setFieldValue('cellphone', value ?? '');
                      }}
                      onCountryChange={(e) => {
                        if (e) {
                          setFieldValue('country_code', e ?? '');
                          setFieldValue(
                            'calling_code',
                            getCountryCallingCode(e) ?? ''
                          );
                        }
                      }}
                      value={values.cellphone}
                      error={errors.cellphone}
                      style={{
                        marginRight: 10,
                        textAlign: 'center',
                        padding: '0 10px',
                        color: 'white',
                        fontSize: 35,
                        borderRadius: 6,
                        fontFamily: 'Barlow Condensed Semi',
                        borderColor: '#00001d',
                      }}
                    />
                  </PhoneContainer>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 40,
                  }}
                >
                  <Button
                    outlined
                    buttonStyle={{
                      maxHeight: 62,
                      backgroundColor: 'white',
                      borderRadius: 8,
                      height: 62,
                      padding: '0 5px',
                      marginRight: 10,
                    }}
                    textColor={'#fa4616'}
                    onClick={() => {
                      setOpenTicketModal(false);
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    buttonStyle={{
                      maxHeight: 62,
                      backgroundColor: '#fa4616',
                      borderRadius: 8,
                      height: 62,
                      padding: '0 5px',
                    }}
                  >
                    SUBMIT
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        isVisible={openRefundModal}
        hideModal={close}
        maxWidth={600}
        noHeader
      >
        {currentTransaction && (
          <RefundModal
            {...props}
            currentTransaction={currentTransaction}
            close={close}
            match={match}
            isUserTransaction={true}
          />
        )}
      </Modal>

      <Modal
        isVisible={openCreditModal}
        hideModal={close}
        maxWidth={600}
        noHeader
      >
        {currentTransaction && (
          <TransactionCreditModal
            {...props}
            currentTransaction={currentTransaction}
            close={close}
          />
        )}
      </Modal>

      <ReaderIpModal
        isVisible={isReaderIpVisible}
        setIsVisible={setIsReaderIpVisible}
      />

      <PrintingModal isVisible={isPrinting} />
    </Container>
  );
};

export default TransactionDetail;
