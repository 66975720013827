import React from 'react';
import ExportEventsReport from '../ExportEventsReport';
import { PrintEventsReport } from '../PrintEventsReport';
import { Container, Column } from './StyledHeader';

const EventReportHeader = ({ margin, type }) => {
  return (
    <Container margin={margin} style={{ justifyContent: 'end' }}>
      <Column noBorder>
        <ExportEventsReport
          type={type}
          headerText={type === 'events' ? 'Events' : 'Transaction'}
        />
        <PrintEventsReport
          type={type}
          headerText={type === 'events' ? 'Events' : 'Transaction'}
        />
      </Column>
    </Container>
  );
};

export default EventReportHeader;
