import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import Container from 'components/Container';
import { NoteList } from 'components/Notes';
import Spacer from 'components/Spacer';
import {
  AddUpdateBtn,
  Header,
  Tab,
  Tabs,
  Content,
} from 'pages/app/Events/AddEvents/styles';
import { setTime } from 'shared/timeUtils';
import AnnualDetails from './components/AnnualDetails';
import { AnnualPages } from './components/AnnualPages';
import AnnualTicketsHeader from './components/AnnualTicketsHeader';
import DateAndTime from './components/DateAndTime';
import Payment from './components/Payment';
import CreateAnnualTicket from './gql/CreateAnnualTicket';
import { useGetAnnualTicket } from './gql/GetAnnualTicket';
import UpdateAnnualTicket from './gql/UpdateAnnualTicket';

const formValues = [
  'id',
  'name',
  'color_code',
  'price',
  'description',
  'track_price',
  'fee_dollar',
  'fee_percent',
  'is_featured',
  'featured_text',
  'sale_start',
  'on_sale_time',
  'sale_end',
  'off_sale_time',
  'start_date',
  'end_date',
  'created_by',
  'updated_by',
  'status',
  'generate_passes',
  'display_events',
  'display_track_series',
  'for_all_events',
  'limit',
  'low_ticket',
  'account_id',
  'show_fee',
  'international_fee',
  'marketing_fee',
  'track_ids',
  'track_id',
  'series_ids',
  'event_ids',
  'type_id',
  'ticket_type',
  'pages',
  'pages_with_order',
  'notes',
];

function getInitialValues(
  ticket,
  keys,
  on_sale_time_modified,
  off_sale_time_modified
) {
  const blankValues = {
    ...keys.reduce((values, key) => ({ ...values, [key]: '' }), {}),
    show_fee: true,
    for_all_events: true,
    event_ids: [],
    pages: [],
    notes: [],
  };

  const initialValues = !ticket
    ? blankValues
    : {
        ...keys.reduce((formValues, key) => {
          return ticket[key]
            ? {
                ...formValues,
                [key]: ticket[key],
              }
            : formValues;
        }, {}),
        series_ids: Array.isArray(ticket.series)
          ? ticket.series.map((series) => ({
              value: series.id,
              label: series.name,
            }))
          : [],
        track_id: Array.isArray(ticket.tracks)
          ? ticket.tracks.map((track) => ({
              value: track.id,
              label: track.name,
            }))
          : [],
        type_id: ticket.type
          ? { label: ticket.type.name, value: ticket.type.id }
          : null,
        pages: ticket.pages
          ? ticket.pages.map((page) => ({
              ...page,
              waivers: page.waivers
                ? page.waivers
                    .filter(Boolean)
                    .reduce(
                      (allWaivers, waiver) => [
                        ...allWaivers,
                        { waiver: waiver },
                      ],
                      []
                    )
                : [],
            }))
          : [],
        pages_with_order: ticket.pages
          ? ticket.pages.map((page) => ({
              ...page,
              waivers: page.waivers
                ? page.waivers
                    .filter(Boolean)
                    .reduce(
                      (allWaivers, waiver) => [
                        ...allWaivers,
                        { waiver: waiver },
                      ],
                      []
                    )
                : [],
            }))
          : [],
        ...(ticket.on_sale_time
          ? { on_sale_time: on_sale_time_modified }
          : null),
        ...(ticket.off_sale_time
          ? { off_sale_time: off_sale_time_modified }
          : null),
        notes: ticket.notes ? ticket.notes : [],
      };

  return initialValues;
}

function getPagesWithOrders(page, index) {
  const { id, name, pdf_url, form_id, waivers, pdf_id, video_url } = page;
  return {
    id,
    name,
    pdf_url,
    form_id,
    waivers,
    order: index,
    pdf_id,
    video_url,
  };
}

function getTicketInput({ data, currentTicket, action }) {
  const input = {
    ...data,
    ...(action === 'update' && currentTicket
      ? { id: parseInt(currentTicket) }
      : null),
    series_ids: data.series_ids
      ? data.series_ids.map((item) => item.value)
      : [],
    track_ids: data.track_id ? data.track_id.map((item) => item.value) : [],
    ticket_type: data.ticket_type ? data.ticket_type : 'annual',
    account_id: data.account_id ? data.account_id?.value : '',
    type_id: data.type_id ? Number(data.type_id.value) : '',
    on_sale_time: data.on_sale_time
      ? moment(data.on_sale_time).format('HH:mm:ss')
      : '',
    off_sale_time: data.off_sale_time
      ? moment(data.off_sale_time).format('HH:mm:ss')
      : '',
    status: data.status ? data.status : 'draft',
    track_price: data.track_price ? `${data.track_price}` : '',
    pages: data.pages
      ? data.pages.map((page) => ({
          ...page,
          waivers: page.waivers
            ? page.waivers
                .filter(Boolean)
                .map((waiver) => parseInt(waiver.waiver, 10))
            : [],
        }))
      : [],
  };

  formValues.forEach((key) => {
    if (input[key] === '' || input[key] === undefined) delete input[key];
  });

  return input;
}

function AddAnnualTickets({ createAnnualTicket, updateAnnualTicket, history }) {
  const [active, setActive] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [on_sale_time_modified, set_on_sale_time_modified] = useState(null);
  const [off_sale_time_modified, set_off_sale_time_modified] = useState(null);

  const params = useParams();
  const currentTicket = params.id;

  const tabsConfig = [
    {
      id: 0,
      name: 'Dates and Times',
    },
    {
      id: 1,
      name: 'Payment',
    },
    {
      id: 2,
      name: 'Annual Details',
    },
    {
      id: 3,
      name: 'Pages',
    },
    {
      id: 4,
      name: 'Notes',
    },
  ];

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  const { data } = useGetAnnualTicket(parseInt(currentTicket));

  useEffect(() => {
    if (data && data.getAnnualTicket) {
      const on_sale_time_modified = data.getAnnualTicket.on_sale_time
        ? setTime(data.getAnnualTicket.on_sale_time)
        : null;
      set_on_sale_time_modified(on_sale_time_modified);
      const off_sale_time_modified = data.getAnnualTicket.off_sale_time
        ? setTime(data.getAnnualTicket.off_sale_time)
        : null;
      set_off_sale_time_modified(off_sale_time_modified);
    }
  }, [data]);

  const successMessage = () =>
    toast.success(
      currentTicket
        ? 'Annual Ticket Updated Successfully'
        : 'Annual Ticket Created Successfully'
    );
  const errorMessage = (response, message) =>
    toast.error(
      message
        ? message
        : response?.global
        ? currentTicket
          ? 'Error Updating Annual Ticket'
          : 'Error Creating Annual Ticket'
        : "There were errors with your submission check the form's field for errors."
    );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValues(
          data ? data.getAnnualTicket : '',
          formValues,
          on_sale_time_modified,
          off_sale_time_modified
        )}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = 'Required';
            errorMessage({}, 'Annual / Season Ticket name is required');
          }

          if ('tickethoss' !== process.env.REACT_APP_PLATFORM)
            if (!values.ticket_type) {
              errors.ticket_type = 'Required';
              errorMessage({}, 'Annual / Season Ticket Type is required');
            }

          if (!values.price && values.price !== 0) {
            errors.price = 'Required';
            errorMessage({}, 'Annual / Season Ticket Price is required');
          }

          if (
            values.ticket_type !== 'registration' &&
            values.price !== 0 &&
            !values.fee_percent &&
            !values.fee_dollar
          ) {
            errors.fee_dollar = 'Required';
            errors.fee_percent = 'Required';
            errorMessage({}, 'Fee Values required');
          }

          if (!values.account_id) {
            errors.account_id = 'Required';
            errorMessage(
              {},
              'Please select an account for the Annual / Season Ticket'
            );
          }

          if (!values.type_id) {
            errors.type_id = 'Required';
            errorMessage(
              {},
              'Please select a category for the Annual / Season Ticket'
            );
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          let response;
          setSubmitting(true);

          if (
            values['pages_with_order'] &&
            values['pages_with_order'].length > 0
          ) {
            values['pages'] = values['pages_with_order'].map((page, index) =>
              getPagesWithOrders(page, index)
            );
          }

          const data = getTicketInput({
            data: values,
            currentTicket,
            action: currentTicket ? 'update' : 'create',
          });

          if (typeof data['notes'] !== 'undefined')
            data['notes'].map((note) => delete note['__typename']);

          delete data['track_id'];
          delete data['pages_with_order'];
          delete data['created_by'];
          delete data['updated_by'];

          if (currentTicket) {
            response = await updateAnnualTicket(data);
          } else {
            response = await createAnnualTicket(data);
          }

          if (!response || response.errors) {
            errorMessage(response);
            setSubmitting(false);
            return setErrors(response.errors);
          } else {
            successMessage();
            setSubmitting(false);
            resetForm();
            history.push('/admin/annualTickets');
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Header>
                <AnnualTicketsHeader
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  SDValue={values.start_date}
                  EDValue={values.end_date}
                  status_color={values.status_color}
                  status={values.status}
                />
              </Header>
            </div>
            <Container style={{ width: '100%', margin: 10 }}>
              <div
                style={{ display: 'flex', flexDirection: 'row', padding: 5 }}
              >
                <div style={{ width: '85%' }}>
                  <Tabs>
                    {tabsConfig.map((tab) => {
                      return (
                        <Tab
                          onClick={(e) => handleClick(e)}
                          active={active === tab.id}
                          id={tab.id}
                        >
                          {tab.name}
                        </Tab>
                      );
                    })}{' '}
                    <Spacer size={10} />
                  </Tabs>
                </div>
                <AddUpdateBtn
                  type="submit"
                  style={{}}
                  disabled={isSubmitting}
                  block
                >
                  {currentTicket ? 'Update Ticket' : 'Add Ticket'}
                </AddUpdateBtn>
              </div>
            </Container>
            <div style={{ width: '95%', padding: 5, margin: 'auto' }}>
              <Content active={active === 0}>
                <DateAndTime
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                />
              </Content>
              <Content active={active === 1}>
                <Payment
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                />
              </Content>{' '}
              <Content active={active === 2}>
                <AnnualDetails
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                />
              </Content>
              <Content active={active === 3}>
                <AnnualPages
                  onChange={({ target, order }) => {
                    const newPages = values.pages.map((page) =>
                      page.order === order
                        ? {
                            ...page,
                            [target.name]: target.value,
                          }
                        : page
                    );
                    const newPagesWithOrder = values.pages_with_order.map(
                      (sponsorship) =>
                        sponsorship.order === order
                          ? {
                              ...sponsorship,
                              [target.name]: target.value,
                            }
                          : sponsorship
                    );
                    handleChange({
                      target: {
                        name: 'pages',
                        value: newPages,
                      },
                    });
                    handleChange({
                      target: {
                        name: 'pages_with_order',
                        value: newPagesWithOrder,
                      },
                    });
                  }}
                  onChangePage={(name, value) => {
                    handleChange({
                      target: {
                        name: name,
                        value,
                      },
                    });
                  }}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  annualPages={values.pages}
                  handleSort={(name, value) => {
                    handleChange({
                      target: {
                        name: name,
                        value,
                      },
                    });
                  }}
                />
              </Content>
              <Content active={active === 4}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: !isMobile ? '60%' : '90%',
                      margin: 20,
                    }}
                  >
                    <NoteList />
                  </div>
                </div>
              </Content>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default compose(
  CreateAnnualTicket,
  UpdateAnnualTicket
)(AddAnnualTickets);
