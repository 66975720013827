import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { useSearchInput, useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { IssueCreditModal } from '../issueCreditModal';
import ExportOpenCreditsReport from './ExportOpenCredits/ExportOpenCreditsReport';
import { PrintOpenCreditsReport } from './ExportOpenCredits/PrintOpenCreditsReport';
import { useGetAccountOpenCredits } from './gql/get-account-open-credits';
import { useGetaccountTotalOpenCredits } from './gql/get-account-total-open-credits';

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

export const FilterContainer = styled.div`
  min-width: 200px;
  margin: 5px;
`;

const OpenCredits = withEventYearFilterContext((props) => {
  const { theme, match } = props;

  const [credits, setCredits] = useState();
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');

  const [showIssueCredit, setShowIssueCredit] = useState(false);

  const { year, setYear } = useState();

  const accountId = match.params.id;
  const result = useGetaccountTotalOpenCredits(accountId);

  const { res, loading } = useGetAccountOpenCredits();

  const {
    input: { accountName },
  } = useSearchInput();

  useEffect(() => {
    if (res && res.getAccountOpenCredits) {
      setCredits(res?.getAccountOpenCredits?.results);
      setCount(res?.getAccountOpenCredits?.count);
    }
  }, [res]);

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  function renderRows(credit) {
    const { id, formatted_name } = credit;

    return {
      name: (
        <Link
          to={`/admin/openCredits/${accountId}/creditDetail/${id}`}
          style={{
            color: theme.colors.primary,
          }}
        >
          <LineHeightText> {formatted_name}</LineHeightText>
        </Link>
      ),
    };
  }

  return !result.data?.getAccountTotalOpenCredits ? (
    <div />
  ) : loading ? (
    <div />
  ) : (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'Row',
          justifyContent: 'end',
          marginTop: 20,
          marginRight: 40,
        }}
      >
        <ExportOpenCreditsReport
          headerText={accountName}
          year={year}
          isAccountReport={true}
          totalCredit={result.data.getAccountTotalOpenCredits}
        />
        <PrintOpenCreditsReport
          headerText={accountName}
          year={year}
          isAccountReport={true}
          totalCredit={result.data.getAccountTotalOpenCredits}
        />
      </div>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Open Credits
              </Text>
              <FilterContainer>
                <YearDropdown showLabel={false} setYear={setYear} />
              </FilterContainer>
              <div style={{ marginLeft: 15 }}>
                <SearchInput
                  placeholder="Search Credits"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div>
            </TitleContainer>
            <CreditButton>
              <Button
                onClick={() => setShowIssueCredit(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Issue Credit'}
              </Button>
            </CreditButton>
          </ContainerWrapper>
        </ContainerHeader>
        {!credits ? (
          <div />
        ) : (
          <div style={{ padding: 20 }}>
            <Table items={credits} columns={columns} renderRows={renderRows} />
            <Pagination
              count={count}
              perPage={15}
              currentPage={currentPage || 1}
            />
          </div>
        )}
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={false}
      />
    </>
  );
});

export default withTheme(OpenCredits);
